import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { CookieService } from 'src/app/models/services/cookie-service/cookie.service';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent {

  constructor(public router: Router,private _bottomSheetRef: MatBottomSheetRef<CookieComponent>,public textService: TextsService, private cookie: CookieService) {}

  more(){    
    this.router.navigate(['/'+this.textService.actLang+'/privacy-policy'], { fragment:'cookie'});
  }

  allowAll(){
    this.cookie.setAll(true);
    localStorage.setItem(environment.cookieStorageKey,'1');
    this._bottomSheetRef.dismiss();
  }

  allowMustHave(){
    this.cookie.setAll(false);
    localStorage.setItem(environment.cookieStorageKey,'1');
    this._bottomSheetRef.dismiss();
  }

  showManage(){

  }
}
