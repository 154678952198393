import { Component, Input, OnInit } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {

  @Input() logo:string;
  @Input() mainText:string;
  @Input() subText:string;
  @Input() list:string;

  constructor(public textService: TextsService) { }

  ngOnInit() {    
  }

}
