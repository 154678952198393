import { fadeInButton } from './../../../animation/fade';
import { Component, OnInit } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import { fadeIn } from 'src/app/animation/fade';
@Component({
  selector: 'app-cover-part',
  templateUrl: './cover-part.component.html',
  styleUrls: ['./cover-part.component.scss'],
  animations: [
    fadeIn,
    fadeInButton
  ]
})
export class CoverPartComponent implements OnInit {

  constructor(public textService: TextsService) {}
    
  ngOnInit(): void {        

  }

}
