<section class="team-part">

  <div class="content">
    <h2 id='team'>{{textService.getText('rolunk_cim')}}</h2>
    <hr>
    <p>{{textService.getText('rolunk_lerias')}}</p>

    <div class="team-holder">
      <div class="item-holder">
        <app-team-badge [name]="'Nagy Norbert'" [title]="textService.getText('rolunk_titulus_3')" [profile]="'/assets/portre/nn.webp'"></app-team-badge>
      </div>
      <div class="item-holder">
        <app-team-badge [name]="'György István'" [title]="textService.getText('rolunk_titulus_1')" [profile]="'/assets/portre/gi.webp'"></app-team-badge>
      </div>
      <div class="item-holder">
        <app-team-badge [name]="'Karakas Dániel'" [title]="textService.getText('rolunk_titulus_2')" [profile]="'/assets/portre/kd.webp'"></app-team-badge>
      </div>
      <!-- <div class="item-holder">
        <app-team-badge [name]="'Szalai János'" [title]="textService.getText('rolunk_titulus_5')" [profile]="'/assets/portre/sj.webp'" ></app-team-badge>
      </div> -->
      <div class="item-holder">
        <app-team-badge [name]="'Gergely Angéla'" [title]="textService.getText('rolunk_titulus_4')" [profile]="'/assets/portre/ga.webp'"></app-team-badge>
      </div>

    </div>
  </div>


  
</section>