import { map, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as locales from 'src/assets/locale/languages.json'; 
import { HttpClient} from '@angular/common/http';
import { Version } from 'src/app/version';

@Injectable({
  providedIn: 'root'
})
export class TextsService {

  languages:any;
  language: string; //hu vagy en
  texts: any[];
  update:Subject<void>;

  constructor(private httpClient: HttpClient) {
    this.languages = (locales as any).default;
    this.texts = [];  
    this.update = new Subject();
    this.language = '';
  }

  public get actLang() {
    return this.language;
  }
  public get getLangs() {
    return this.languages;
  }

  public loadLang(code: string): Observable<any>{
    return this.httpClient.get('assets/locale/'+code+'.json?v='+Version[code],{observe: 'body',responseType: 'json'}).pipe(map((response:Object) =>{      
      this.texts = response as any[];
      this.update.next();
    }));  
  }

  public setPageLanguage(code:string){
    this.language = code;
    document.documentElement.setAttribute('lang', code);
  }

  public getText(code: string) :string {
    if(this.texts == null){
      return code;
    }
    return  this.texts[code] ?? code;
  }

  public onLanguage() : Subject<void>{
    return this.update;
  }
}
