<app-navigation></app-navigation>

<div #wrapper class="wrapper">   
    <app-cover-part></app-cover-part>
    <app-info-part></app-info-part>
    <app-team-part></app-team-part>
    <app-references-part></app-references-part>
    <app-project-part></app-project-part>
    <app-contacts-part></app-contacts-part> 
    <app-footer></app-footer>
</div>
