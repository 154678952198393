import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';

@Component({
  selector: 'app-privacy-part',
  templateUrl: './privacy-part.component.html',
  styleUrls: ['./privacy-part.component.scss']
})
export class PrivacyPartComponent implements OnInit {

  constructor(public textService: TextsService,private meta: Meta) { }

  ngOnInit() {
    this.meta.updateTag({ name: 'og:title', content: this.textService.getText('adatvedelmi_iranyelvek') });
    this.meta.updateTag({ name: 'og:url', content:  document.URL });    
    this.meta.updateTag({ name: 'og:description', content: this.textService.getText("meta_description_adatvedelmi") });
    this.meta.updateTag({ name: 'description', content: this.textService.getText("meta_description_adatvedelmi") });
  }

}
