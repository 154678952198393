<div class="holder">

  <img [src]="image" alt="">
  <div class="right">
    <div class="title">{{title}}</div>
    <div class="description">{{description}}</div>
    <div class="date">{{date}}</div>
  </div>

</div>
