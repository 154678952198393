<div class="table-container" role="table" aria-label="Sütik">
  <div class="flex-table header" role="rowgroup">
    <div class="flex-row first" role="columnheader">N&eacute;v</div>
    <div class="flex-row" role="columnheader">Szolg&aacute;ltat&oacute;</div>
    <div class="flex-row" role="columnheader">C&eacute;l</div>
    <div class="flex-row" role="columnheader">Lej&aacute;rat</div>
    <div class="flex-row" role="columnheader">T&iacute;pus</div>
  </div>
  <div class="flex-table row" role="rowgroup">
    <div class="flex-row first grey" role="cell">Működéshez szükséges sütik</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">cookie</div>
    <div class="flex-row" role="cell">Sidequest</div>
    <div class="flex-row" role="cell">Megjegyezni süti felugró beállítást</div>
    <div class="flex-row" role="cell">nincs</div>
    <div class="flex-row" role="cell">local storage</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">analytics_enabled</div>
    <div class="flex-row" role="cell">Sidequest</div>
    <div class="flex-row" role="cell">Megjegyezni az analitikai süti beállítást</div>
    <div class="flex-row" role="cell">nincs</div>
    <div class="flex-row" role="cell">local storage</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">_grecaptcha</div>
    <div class="flex-row" role="cell">Google reCaptcha</div>
    <div class="flex-row" role="cell">Spam támadások ellen való védelem</div>
    <div class="flex-row" role="cell">munkamenet</div>
    <div class="flex-row" role="cell">local storage</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">_grecaptcha</div>
    <div class="flex-row" role="cell">Google reCaptcha</div>
    <div class="flex-row" role="cell">Felhasználók megkülönböztetése, azonosítása</div>
    <div class="flex-row" role="cell">2 év</div>
    <div class="flex-row" role="cell">http</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">_grecaptcha</div>
    <div class="flex-row" role="cell">Google reCaptcha</div>
    <div class="flex-row" role="cell">Felhasználók megkülönböztetése, azonosítása</div>
    <div class="flex-row" role="cell">24 év</div>
    <div class="flex-row" role="cell">http</div>
  </div>
  <div class="flex-table row" role="rowgroup">
    <div class="flex-row first grey" role="cell">Kényelmi sütik</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">lang</div>
    <div class="flex-row" role="cell">Sidequest</div>
    <div class="flex-row" role="cell">Megjegyezni a felhasználó által kiválasztott oldal nyelvet</div>
    <div class="flex-row" role="cell">nincs</div>
    <div class="flex-row" role="cell">local storage</div>
  </div>
  <div class="flex-table row" role="rowgroup">
    <div class="flex-row first grey" role="cell">Statisztikai sütik</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">_ga</div>
    <div class="flex-row" role="cell">Google Analitics</div>
    <div class="flex-row" role="cell">Felhasználók megkülönböztetése, azonosítása</div>
    <div class="flex-row" role="cell">2 év</div>
    <div class="flex-row" role="cell">http</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">_gid</div>
    <div class="flex-row" role="cell">Google Analitics</div>
    <div class="flex-row" role="cell">Felhasználók megkülönböztetése, azonosítása</div>
    <div class="flex-row" role="cell">24 óra</div>
    <div class="flex-row" role="cell">http</div>
  </div>
  <div class="flex-table row" role="rowgroup">    
    <div class="flex-row first" role="cell">_ga_#</div>
    <div class="flex-row" role="cell">Google Analitics</div>
    <div class="flex-row" role="cell">Felhasználók megkülönböztetése, azonosítása</div>
    <div class="flex-row" role="cell">2 év</div>
    <div class="flex-row" role="cell">http</div>
  </div>
</div>