import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import { Blog } from './blog';
import { Version } from 'src/app/version';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  blogs:Observable<Blog[]>;

  constructor(private httpClient: HttpClient,private texts: TextsService) {
    this.blogs = httpClient.get<Blog[]>('assets/locale/blog_'+texts.actLang+'.json?v='+Version['blog_'+texts.actLang]); 
  }

  public getBlogPosts(): Observable<Blog[]>{
    return this.blogs;
  }

  public getPost(slug:any): Observable<Blog>{
    return this.blogs.pipe(map((blogs) => {
      for(let blog of blogs){
        if(blog.slug == slug){
          return blog;
        }
      }
      return null;
    }
    ));
  }

  public getPostHtml(url:string): Observable<any>{        
    return this.httpClient.get(url+'?v'+Version[url.split('/').pop().replace('.html','')],{responseType:'text'}); 
  }

}