<div class="block"></div>
<section>
  <div class="head">
    <div class="content">
      <div>
        <h1>{{textService.getText('blog')}}</h1>
      </div>
      <hr>
    </div>
  </div>
  <div class="body">
    <div class="content">
      <div class="item" *ngFor="let blog of blogs.getBlogPosts() | async" >
        <a [routerLink]="'/'+textService.actLang+'/blog/'+blog.slug">
          <app-blog-card [image]="blog.imageUrl" [title]="blog.title" [description]="blog.description" [date]="blog.created"></app-blog-card>
        </a> 
      </div>
    </div>
  </div>
</section>