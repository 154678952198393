import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public navState: boolean = false; //Éppen navigálás/scrollozás történik, ami blokkol bizonyos műveleteket ideiglenesen
  public activeItem = '';
 
  constructor() {}

  public setActiveItem(item:string){
    this.activeItem = item;
  }

  public scrollTo(fragment:string){

    var top = 0;
    
    if(fragment != '' && document.querySelector("#"+fragment) != null){
      top = document.querySelector("#"+fragment)!.getBoundingClientRect().top + window.pageYOffset - 72
    }

    window.scrollTo({
      top: top,
      behavior: "smooth"
    });
  }
}

