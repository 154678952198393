<div class="holder" [ngClass]="transparent ? 'transparent' : ''">

  <div class="side">
    <div class="number">
      <div>{{number}}</div>
    </div>
  </div>
  <div class="card">
    <div class="title">{{title}}</div>
    <div class="text">{{text}}</div>
  </div>

</div>
