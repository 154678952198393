<div class="block"></div>
<section>
  <div class="head">
    <div class="content">
      <div>
        <h1>{{blog?.title}}</h1>
      </div>
      <hr>
    </div>
  </div>
  <div class="body">
    <div class="content">
      <div class="download" *ngIf="blog?.pdfUrl != ''">
        <a [href]="blog?.pdfUrl" target="_blank" download>{{textService.getText('blog_letoltes')}}</a>
      </div>
      <div [innerHTML]="content | dompurify"></div>

    </div>
  </div>
</section>