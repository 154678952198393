<div class="block"></div>
<section>
  <div class="head">
    <div class="content">
      <div>
        <h1>{{textService.getText('adatvedelmi_iranyelvek')}}</h1>
      </div>
      <hr>
    </div>
  </div>
  <div class="body">
    <div class="content">
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Jelen adatkezel&eacute;si
          t&aacute;j&eacute;koztat&oacute; c&eacute;lja, hogy </span><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Sidequest Korl&aacute;tolt
            Felelőss&eacute;gű T&aacute;rsas&aacute;g</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">, mint adatkezelő
          (tov&aacute;bbiakban: Adatkezelő) az adatv&eacute;delemre vonatkoz&oacute; hat&aacute;lyos
          jogszab&aacute;lyok, &iacute;gy k&uuml;l&ouml;n&ouml;sen az inform&aacute;ci&oacute;s
          &ouml;nrendelkez&eacute;si jogr&oacute;l &eacute;s az inform&aacute;ci&oacute;szabads&aacute;gr&oacute;l
          sz&oacute;l&oacute; 2011. &eacute;vi CXII. t&ouml;rv&eacute;ny (tov&aacute;bbiakban: Inf&oacute; tv.) valamint
          az Eur&oacute;pai Parlament &eacute;s a Tan&aacute;cs 2016/679 sz&aacute;m&uacute; rendelet
          (tov&aacute;bbiakban: GDPR rendelet) rendelkez&eacute;seivel &ouml;sszhangban
          t&aacute;j&eacute;koztat&aacute;st ny&uacute;jtson a </span><a style="text-decoration: none;"
          href="https://sidequest.hu"><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #1155cc; text-decoration: underline; text-decoration-skip-ink: none;">https://sidequest.hu</span></a><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> weboldal (tov&aacute;bbiakban:
          Weboldal) l&aacute;togat&oacute;ja, mint &eacute;rintett (tov&aacute;bbiakban: &Eacute;rintett)
          sz&aacute;m&aacute;ra a weboldal haszn&aacute;lata sor&aacute;n, valamint az Adatkezelővel t&ouml;rt&eacute;nő
          kapcsolatfelv&eacute;tel eset&eacute;n kezelt szem&eacute;lyes adatainak kezel&eacute;s&eacute;ről.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">AZ ADATKEZELŐ
            ADATAI</span></strong></p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">N&eacute;v: </span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Sidequest Korl&aacute;tolt
          Felelőss&eacute;gű T&aacute;rsas&aacute;g&nbsp;</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Sz&eacute;khely:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> 1191 Budapest,
          R&aacute;k&oacute;czi utca 27. 1. em. 3.&nbsp;</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">C&eacute;gjegyz&eacute;ksz&aacute;m:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> 01-09-377242</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ad&oacute;sz&aacute;m:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> 28788490-2-43</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">FOGALMAK</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Szem&eacute;lyes
            adat:</span></strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">
          azonos&iacute;tott vagy azonos&iacute;that&oacute; term&eacute;szetes szem&eacute;lyre
          (&bdquo;&Eacute;rintett&rdquo;) vonatkoz&oacute; b&aacute;rmely inform&aacute;ci&oacute;.
          Azonos&iacute;that&oacute; az a term&eacute;szetes szem&eacute;ly, aki k&ouml;zvetlen vagy k&ouml;zvetett
          m&oacute;don, k&uuml;l&ouml;n&ouml;sen valamely azonos&iacute;t&oacute;, p&eacute;ld&aacute;ul n&eacute;v,
          sz&aacute;m, helymeghat&aacute;roz&oacute; adat, online azonos&iacute;t&oacute; vagy a term&eacute;szetes
          szem&eacute;ly testi, fiziol&oacute;giai, genetikai, szellemi, gazdas&aacute;gi, kultur&aacute;lis vagy
          szoci&aacute;lis azonoss&aacute;g&aacute;ra vonatkoz&oacute; egy, vagy t&ouml;bb t&eacute;nyező alapj&aacute;n
          azonos&iacute;that&oacute;.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">&Eacute;rintett:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> b&aacute;rmely
          inform&aacute;ci&oacute; alapj&aacute;n azonos&iacute;tott vagy azonos&iacute;that&oacute; term&eacute;szetes
          szem&eacute;ly, akinek szem&eacute;lyes adatait az Adatkezelő kezeli, &iacute;gy Weboldal
          l&aacute;togat&oacute;ja, illetve a Weboldalhoz tartoz&oacute; k&ouml;z&ouml;ss&eacute;gi &uuml;zleti oldal
          l&aacute;togat&oacute;ja.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatkezel&eacute;s:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az alkalmazott
          elj&aacute;r&aacute;st&oacute;l f&uuml;ggetlen&uuml;l az adaton v&eacute;gzett b&aacute;rmely művelet vagy a
          műveletek &ouml;sszess&eacute;ge, &iacute;gy k&uuml;l&ouml;n&ouml;sen gyűjt&eacute;se, felv&eacute;tele,
          r&ouml;gz&iacute;t&eacute;se, rendszerez&eacute;se, t&aacute;rol&aacute;sa, megv&aacute;ltoztat&aacute;sa,
          felhaszn&aacute;l&aacute;sa, lek&eacute;rdez&eacute;se, tov&aacute;bb&iacute;t&aacute;sa,
          nyilv&aacute;noss&aacute;gra hozatala, &ouml;sszehangol&aacute;sa vagy &ouml;sszekapcsol&aacute;sa,
          z&aacute;rol&aacute;sa, t&ouml;rl&eacute;se &eacute;s megsemmis&iacute;t&eacute;se, valamint az adat
          tov&aacute;bbi felhaszn&aacute;l&aacute;s&aacute;nak megakad&aacute;lyoz&aacute;sa, f&eacute;nyk&eacute;p-,
          hang- vagy k&eacute;pfelv&eacute;tel k&eacute;sz&iacute;t&eacute;se, valamint a szem&eacute;ly
          azonos&iacute;t&aacute;s&aacute;ra alkalmas fizikai jellemzők (pl. ujj- vagy teny&eacute;rnyomat, DNS-minta,
          &iacute;riszk&eacute;p) r&ouml;gz&iacute;t&eacute;se.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatkezelő:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az a term&eacute;szetes vagy jogi
          szem&eacute;ly, illetve jogi szem&eacute;lyis&eacute;ggel nem rendelkező szervezet, amely
          &ouml;n&aacute;ll&oacute;an vagy m&aacute;sokkal egy&uuml;tt az adatok kezel&eacute;s&eacute;nek
          c&eacute;lj&aacute;t meghat&aacute;rozza, az adatkezel&eacute;sre (bele&eacute;rtve a felhaszn&aacute;lt
          eszk&ouml;zt) vonatkoz&oacute; d&ouml;nt&eacute;seket meghozza &eacute;s v&eacute;grehajtja, vagy az
          adatfeldolgoz&oacute;val v&eacute;grehajtatja.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatfeldolgoz&oacute;:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az a term&eacute;szetes vagy jogi
          szem&eacute;ly, illetve jogi szem&eacute;lyis&eacute;ggel nem rendelkező szervezet, aki vagy amely -
          t&ouml;rv&eacute;nyben vagy az Eur&oacute;pai Uni&oacute; k&ouml;telező jogi aktus&aacute;ban
          meghat&aacute;rozott keretek k&ouml;z&ouml;tt &eacute;s felt&eacute;telekkel - az Adatkezelő
          megb&iacute;z&aacute;s&aacute;b&oacute;l vagy rendelkez&eacute;se alapj&aacute;n szem&eacute;lyes adatokat
          kezel.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatfeldolgoz&aacute;s:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az Adatkezelő
          megb&iacute;z&aacute;s&aacute;b&oacute;l vagy rendelkez&eacute;se alapj&aacute;n elj&aacute;r&oacute;
          adatfeldolgoz&oacute; &aacute;ltal v&eacute;gzett adatkezel&eacute;si műveletek &ouml;sszess&eacute;ge.</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatkezel&eacute;s
            korl&aacute;toz&aacute;sa:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> a t&aacute;rolt adat
          z&aacute;rol&aacute;sa az adat tov&aacute;bbi kezel&eacute;s&eacute;nek korl&aacute;toz&aacute;sa
          c&eacute;lj&aacute;b&oacute;l t&ouml;rt&eacute;nő megjel&ouml;l&eacute;se &uacute;tj&aacute;n.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adattov&aacute;bb&iacute;t&aacute;s:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az adat meghat&aacute;rozott
          harmadik szem&eacute;ly sz&aacute;m&aacute;ra t&ouml;rt&eacute;nő hozz&aacute;f&eacute;rhetőv&eacute;
          t&eacute;tele.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">K&ouml;zvetett
          adattov&aacute;bb&iacute;t&aacute;s: szem&eacute;lyes adatnak valamely harmadik orsz&aacute;gban vagy
          nemzetk&ouml;zi szervezet keret&eacute;ben adatkezel&eacute;st folytat&oacute; Adatkezelő vagy
          adatfeldolgoz&oacute; r&eacute;sz&eacute;re tov&aacute;bb&iacute;t&aacute;sa &uacute;tj&aacute;n valamely
          m&aacute;s harmadik orsz&aacute;gban vagy nemzetk&ouml;zi szervezet keret&eacute;ben adatkezel&eacute;st
          folytat&oacute; Adatkezelő vagy adatfeldolgoz&oacute; r&eacute;sz&eacute;re t&ouml;rt&eacute;nő
          tov&aacute;bb&iacute;t&aacute;sa.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Harmadik
            orsz&aacute;g</span></strong><strong><em><span
              style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">:</span></em></strong><em><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp;</span></em><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">minden olyan &aacute;llam, amely
          nem EGT-&aacute;llam.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatv&eacute;delmi
            incidens:</span></strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">
          az adatbiztons&aacute;g olyan s&eacute;relme, amely a tov&aacute;bb&iacute;tott, t&aacute;rolt vagy m&aacute;s
          m&oacute;don kezelt szem&eacute;lyes adatok v&eacute;letlen vagy jogellenes
          megsemmis&uuml;l&eacute;s&eacute;t, elveszt&eacute;s&eacute;t, m&oacute;dosul&aacute;s&aacute;t, jogosulatlan
          tov&aacute;bb&iacute;t&aacute;s&aacute;t vagy nyilv&aacute;noss&aacute;gra hozatal&aacute;t, vagy az azokhoz
          val&oacute; jogosulatlan hozz&aacute;f&eacute;r&eacute;st eredm&eacute;nyezi.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatt&ouml;rl&eacute;s:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az adat felismerhetetlenn&eacute;
          t&eacute;tele oly m&oacute;don, hogy a helyre&aacute;ll&iacute;t&aacute;sa t&ouml;bb&eacute; nem
          lehets&eacute;ges.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Adatmegsemmis&iacute;t&eacute;s:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az adatot tartalmaz&oacute;
          adathordoz&oacute; teljes fizikai megsemmis&iacute;t&eacute;se.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">C&iacute;mzett:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az a term&eacute;szetes vagy jogi
          szem&eacute;ly, illetve jogi szem&eacute;lyis&eacute;ggel nem rendelkező szervezet, aki vagy amely
          r&eacute;sz&eacute;re szem&eacute;lyes adatot az Adatkezelő, illetve az adatfeldolgoz&oacute;
          hozz&aacute;f&eacute;rhetőv&eacute; tesz; Azon k&ouml;zhatalmi szervek, amelyek egy egyedi vizsg&aacute;lat
          keret&eacute;ben az uni&oacute;s vagy a tag&aacute;llami joggal &ouml;sszhangban f&eacute;rhetnek hozz&aacute;
          szem&eacute;lyes adatokhoz, nem minős&uuml;lnek c&iacute;mzettnek; az eml&iacute;tett adatok e k&ouml;zhatalmi
          szervek &aacute;ltali kezel&eacute;se meg kell, hogy feleljen az adatkezel&eacute;s c&eacute;ljainak
          megfelelően az alkalmazand&oacute; adatv&eacute;delmi szab&aacute;lyoknak.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Harmadik
            szem&eacute;ly</span></strong><strong><em><span
              style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">:</span></em></strong><em><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp;</span></em><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">olyan term&eacute;szetes vagy jogi
          szem&eacute;ly, illetve jogi szem&eacute;lyis&eacute;ggel nem rendelkező szervezet, aki vagy amely nem azonos
          az &Eacute;rintettel, az Adatkezelővel, az adatfeldolgoz&oacute;val vagy azokkal a szem&eacute;lyekkel, akik
          az Adatkezelő vagy adatfeldolgoz&oacute; k&ouml;zvetlen ir&aacute;ny&iacute;t&aacute;sa alatt a
          szem&eacute;lyes adatok kezel&eacute;s&eacute;re ir&aacute;nyul&oacute; műveleteket v&eacute;geznek.</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Weboldal:</span></strong><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;"> az Adatkezelő <a
            href="https://sidequest.hu">https://sidequest.hu</a> url c&iacute;m alatt tal&aacute;lhat&oacute;
          weboldal.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">S&uuml;ti vagy cookie:
          </span></strong><span style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az az
          inform&aacute;ci&oacute;, amit egy&nbsp;weboldal megnyit&aacute;sakor a felhaszn&aacute;l&oacute;
          g&eacute;p&eacute;n t&aacute;rol&oacute;dik.</span></p>

      <p style="line-height: 1.295; text-align: center; margin-top: 32pt; margin-bottom: 16pt;"><strong><span
            style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000; font-variant-caps: small-caps;">AZ
            ADATKEZEL&Eacute;SRE VONATKOZ&Oacute; &Aacute;LTAL&Aacute;NOS SZAB&Aacute;LYOK</span></strong></p>

      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000; font-variant-caps: small-caps;">ADATBIZTONS&Aacute;GI
            INT&Eacute;ZKED&Eacute;SEK</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az adatkezel&eacute;st oly
          m&oacute;don v&eacute;gzi az Adatkezelő, hogy megfelelő technikai vagy szervez&eacute;si
          int&eacute;zked&eacute;sek alkalmaz&aacute;s&aacute;val biztos&iacute;tva legyen a szem&eacute;lyes adatok
          megfelelő biztons&aacute;ga, az adatok jogosulatlan vagy jogellenes kezel&eacute;s&eacute;vel, v&eacute;letlen
          elveszt&eacute;s&eacute;vel, megsemmis&iacute;t&eacute;s&eacute;vel vagy k&aacute;rosod&aacute;s&aacute;val
          szembeni v&eacute;delem, &iacute;gy k&uuml;l&ouml;n&ouml;sen arra, hogy a kezelt adat:</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) az arra feljogos&iacute;tottak
          sz&aacute;m&aacute;ra hozz&aacute;f&eacute;rhető (rendelkez&eacute;sre &aacute;ll&aacute;s);</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) hiteless&eacute;ge &eacute;s
          hiteles&iacute;t&eacute;se biztos&iacute;tott (adatkezel&eacute;s hiteless&eacute;ge);</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">c) v&aacute;ltozatlans&aacute;ga
          igazolhat&oacute; (adatintegrit&aacute;s);</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">d) a jogosulatlan
          hozz&aacute;f&eacute;r&eacute;s ellen v&eacute;dett (adat bizalmass&aacute;ga) legyen.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az adatfeldolgoz&oacute;
          &aacute;ltal haszn&aacute;lt sz&aacute;m&iacute;t&aacute;stechnikai rendszerek megfelelő biztons&aacute;ggal
          rendelkeznek, a felv&eacute;telek biztons&aacute;gi ment&eacute;se biztos&iacute;tott.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő mindent megtesz egy
          esetleges adatv&eacute;delmi incidens elker&uuml;l&eacute;se &eacute;rdek&eacute;ben. Amennyiben az
          m&eacute;gis bek&ouml;vetkezne, &uacute;gy azt a GDPR-nak megfelelően kezelj&uuml;k, 72 &oacute;r&aacute;n
          bel&uuml;l bejelentj&uuml;k &eacute;s megtesz&uuml;nk mindent az esetleges k&aacute;rok
          minimaliz&aacute;l&aacute;sa &eacute;rdek&eacute;ben &eacute;s az &Eacute;rintetteket halad&eacute;ktalanul
          t&aacute;j&eacute;koztatjuk sz&uuml;ks&eacute;g eset&eacute;n.</span></p>

      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">ADATV&Eacute;DELMI
            TISZTVISELŐ</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő nem alkalmaz
          adatv&eacute;delmi tisztviselőt.</span></p>

      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">AZ &Eacute;RINTETTEK JOGAI
            (&Eacute;RINTETTI JOGOK)</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintettet a
          szem&eacute;lyes adatainak kezel&eacute;s&eacute;vel &ouml;sszef&uuml;gg&eacute;sben az al&aacute;bbi jogok
          illetik meg.&nbsp;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogosult arra,
          hogy az Adatkezelő &eacute;s az annak megb&iacute;z&aacute;s&aacute;b&oacute;l vagy rendelkez&eacute;se
          alapj&aacute;n elj&aacute;r&oacute; adatfeldolgoz&oacute; &aacute;ltal kezelt szem&eacute;lyes adatai
          vonatkoz&aacute;s&aacute;ban az e t&ouml;rv&eacute;nyben meghat&aacute;rozott felt&eacute;telek szerint</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) az adatkezel&eacute;ssel
          &ouml;sszef&uuml;ggő t&eacute;nyekről az adatkezel&eacute;s megkezd&eacute;s&eacute;t megelőzően
          t&aacute;j&eacute;koztat&aacute;st kapjon (a tov&aacute;bbiakban: előzetes
          t&aacute;j&eacute;koz&oacute;d&aacute;shoz val&oacute; jog),</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) k&eacute;relm&eacute;re
          szem&eacute;lyes adatait &eacute;s az azok kezel&eacute;s&eacute;vel &ouml;sszef&uuml;ggő
          inform&aacute;ci&oacute;kat az Adatkezelő a rendelkez&eacute;s&eacute;re bocs&aacute;ssa (a
          tov&aacute;bbiakban: hozz&aacute;f&eacute;r&eacute;shez val&oacute; jog),</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">c) k&eacute;relm&eacute;re,
          valamint t&ouml;rv&eacute;nyben meghat&aacute;rozott tov&aacute;bbi esetekben szem&eacute;lyes adatait az
          Adatkezelő helyesb&iacute;tse, illetve kieg&eacute;sz&iacute;tse (a tov&aacute;bbiakban:
          helyesb&iacute;t&eacute;shez val&oacute; jog),</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">d) k&eacute;relm&eacute;re,
          valamint t&ouml;rv&eacute;nyben meghat&aacute;rozott tov&aacute;bbi esetekben szem&eacute;lyes adatai
          kezel&eacute;s&eacute;t az Adatkezelő korl&aacute;tozza (a tov&aacute;bbiakban: az adatkezel&eacute;s
          korl&aacute;toz&aacute;s&aacute;hoz val&oacute; jog),</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">e) k&eacute;relm&eacute;re,
          valamint t&ouml;rv&eacute;nyben meghat&aacute;rozott tov&aacute;bbi esetekben szem&eacute;lyes adatait az
          Adatkezelő t&ouml;r&ouml;lje (a tov&aacute;bbiakban: t&ouml;rl&eacute;shez val&oacute; jog).</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett e jogait az
          Adatkezelő sz&eacute;khely&eacute;re c&iacute;mzett postai vagy elektronikus levelez&eacute;si
          c&iacute;m&eacute;re c&iacute;mzett elektronikus lev&eacute;l &uacute;tj&aacute;n k&eacute;rheti.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett a fent
          &iacute;rtakon t&uacute;l panasszal fordulhat a fel&uuml;gyeleti hat&oacute;s&aacute;ghoz, illetve jogai
          megs&eacute;rt&eacute;se eset&eacute;n b&iacute;r&oacute;s&aacute;ghoz fordulhat.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogainak
          r&eacute;szletes tartalm&aacute;t az al&aacute;bbi pontok tartalmazz&aacute;k.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">1.
            T&aacute;j&eacute;koztat&aacute;shoz val&oacute; jog &eacute;s hozz&aacute;f&eacute;r&eacute;shez
            val&oacute; jog</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő az &aacute;ltala,
          illetve a megb&iacute;z&aacute;s&aacute;b&oacute;l vagy rendelkez&eacute;se alapj&aacute;n
          elj&aacute;r&oacute; adatfeldolgoz&oacute; &aacute;ltal v&eacute;gzett adatkezel&eacute;si műveletek
          megkezd&eacute;s&eacute;t megelőzően vagy legk&eacute;sőbb az első adatkezel&eacute;si művelet
          megkezd&eacute;s&eacute;t k&ouml;vetően halad&eacute;ktalanul, illetve &Eacute;rintett k&eacute;relm&eacute;re
          b&aacute;rmikor t&aacute;j&eacute;koztat&aacute;st ad az &Eacute;rintett &aacute;ltala kezelt, illetve az
          &aacute;ltala vagy rendelkez&eacute;se szerint megb&iacute;zott adatfeldolgoz&oacute; &aacute;ltal
          feldolgozott adatair&oacute;l, azok forr&aacute;s&aacute;r&oacute;l, az adatkezel&eacute;s
          c&eacute;lj&aacute;r&oacute;l, jogalapj&aacute;r&oacute;l, időtartam&aacute;r&oacute;l, az
          adatfeldolgoz&oacute; nev&eacute;ről, c&iacute;m&eacute;ről &eacute;s az adatkezel&eacute;ssel
          &ouml;sszef&uuml;ggő tev&eacute;kenys&eacute;g&eacute;ről, az adatv&eacute;delmi incidens
          k&ouml;r&uuml;lm&eacute;nyeiről, hat&aacute;sair&oacute;l &eacute;s az
          elh&aacute;r&iacute;t&aacute;s&aacute;ra megtett int&eacute;zked&eacute;sekről, tov&aacute;bb&aacute; - az
          &Eacute;rintett szem&eacute;lyes adatainak tov&aacute;bb&iacute;t&aacute;sa eset&eacute;n - az
          adattov&aacute;bb&iacute;t&aacute;s jogalapj&aacute;r&oacute;l &eacute;s c&iacute;mzettj&eacute;ről.</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogosult arra,
          hogy az Adatkezelőtől visszajelz&eacute;st kapjon arra vonatkoz&oacute;an, hogy szem&eacute;lyes adatainak
          kezel&eacute;se folyamatban van-e, &eacute;s ha ilyen adatkezel&eacute;s folyamatban van, jogosult arra, hogy
          a szem&eacute;lyes adatokhoz &eacute;s a k&ouml;vetkező inform&aacute;ci&oacute;khoz
          hozz&aacute;f&eacute;r&eacute;st kapjon:</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) az adatkezel&eacute;s
          c&eacute;ljai;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) az &Eacute;rintett
          szem&eacute;lyes adatok kateg&oacute;ri&aacute;i;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">c) azon c&iacute;mzettek vagy
          c&iacute;mzettek kateg&oacute;ri&aacute;i, akikkel, illetve amelyekkel a szem&eacute;lyes adatokat
          k&ouml;z&ouml;lt&eacute;k vagy k&ouml;z&ouml;lni fogj&aacute;k, ide&eacute;rtve k&uuml;l&ouml;n&ouml;sen a
          harmadik orsz&aacute;gbeli c&iacute;mzetteket, illetve a nemzetk&ouml;zi szervezeteket;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">d) a szem&eacute;lyes adatok
          t&aacute;rol&aacute;s&aacute;nak tervezett időtartama, vagy ha ez nem lehets&eacute;ges, ezen időtartam
          meghat&aacute;roz&aacute;s&aacute;nak szempontjai;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">e) az &Eacute;rintett azon joga,
          hogy k&eacute;relmezheti az Adatkezelőtől a r&aacute; vonatkoz&oacute; szem&eacute;lyes adatok
          helyesb&iacute;t&eacute;s&eacute;t, t&ouml;rl&eacute;s&eacute;t vagy kezel&eacute;s&eacute;nek
          korl&aacute;toz&aacute;s&aacute;t, &eacute;s tiltakozhat az ilyen szem&eacute;lyes adatok kezel&eacute;se
          ellen;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">f) a valamely fel&uuml;gyeleti
          hat&oacute;s&aacute;ghoz c&iacute;mzett panasz beny&uacute;jt&aacute;s&aacute;nak joga;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">g) ha az adatokat nem az
          &Eacute;rintettől gyűjt&ouml;tt&eacute;k, a forr&aacute;sukra vonatkoz&oacute; minden el&eacute;rhető
          inform&aacute;ci&oacute;;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">h) amennyiben az Adatkezelő olyan,
          kiz&aacute;r&oacute;lag automatiz&aacute;lt adatkezel&eacute;sen &ndash; ide&eacute;rtve a
          profilalkot&aacute;st is &ndash; alapul&oacute; d&ouml;nt&eacute;st hoz az &Eacute;rintett
          vonatkoz&aacute;s&aacute;ban, amely r&aacute; n&eacute;zve joghat&aacute;ssal j&aacute;rna vagy őt
          hasonl&oacute;k&eacute;ppen jelentős m&eacute;rt&eacute;kben &eacute;rinten&eacute;, ennek
          t&eacute;ny&eacute;ről, valamint ilyen esetben legal&aacute;bb a d&ouml;nt&eacute;shozatal sor&aacute;n
          alkalmazott logik&aacute;ra &eacute;s arra vonatkoz&oacute; &eacute;rthető inform&aacute;ci&oacute;k, hogy az
          ilyen adatkezel&eacute;s milyen jelentős&eacute;ggel b&iacute;r, &eacute;s az &Eacute;rintettre n&eacute;zve
          milyen v&aacute;rhat&oacute; k&ouml;vetkezm&eacute;nyekkel j&aacute;r.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">2. Helyesb&iacute;t&eacute;shez
            val&oacute; jog</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A helyesb&iacute;t&eacute;shez
          val&oacute; jog &eacute;rv&eacute;nyes&uuml;l&eacute;se &eacute;rdek&eacute;ben az Adatkezelő, ha az
          &aacute;ltala, illetve a megb&iacute;z&aacute;s&aacute;b&oacute;l vagy rendelkez&eacute;se alapj&aacute;n
          elj&aacute;r&oacute; adatfeldolgoz&oacute; &aacute;ltal kezelt szem&eacute;lyes adatok pontatlanok,
          helytelenek vagy hi&aacute;nyosak, azokat - k&uuml;l&ouml;n&ouml;sen az &Eacute;rintett
          k&eacute;relm&eacute;re - halad&eacute;ktalanul pontos&iacute;tja vagy helyesb&iacute;ti, illetve ha az az
          adatkezel&eacute;s c&eacute;lj&aacute;val &ouml;sszeegyeztethető, az &Eacute;rintett &aacute;ltal
          rendelkez&eacute;s&eacute;re bocs&aacute;tott tov&aacute;bbi szem&eacute;lyes adatokkal vagy az
          &Eacute;rintett &aacute;ltal a kezelt szem&eacute;lyes adatokhoz fűz&ouml;tt nyilatkozattal
          kieg&eacute;sz&iacute;ti (a tov&aacute;bbiakban egy&uuml;tt: helyesb&iacute;t&eacute;s).</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Mentes&uuml;l e
          k&ouml;telezetts&eacute;g al&oacute;l az Adatkezelő, ha</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) a pontos,
          helyt&aacute;ll&oacute;, illetve hi&aacute;nytalan szem&eacute;lyes adatok nem &aacute;llnak
          rendelkez&eacute;s&eacute;re &eacute;s azokat az &Eacute;rintett sem bocs&aacute;tja a
          rendelkez&eacute;s&eacute;re, vagy</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) az &Eacute;rintett &aacute;ltal
          rendelkez&eacute;s&eacute;re bocs&aacute;tott szem&eacute;lyes adatok val&oacute;dis&aacute;ga
          k&eacute;ts&eacute;get kiz&aacute;r&oacute;an nem &aacute;llap&iacute;that&oacute; meg.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha az Adatkezelő az &aacute;ltala,
          illetve a megb&iacute;z&aacute;s&aacute;b&oacute;l vagy rendelkez&eacute;se alapj&aacute;n
          elj&aacute;r&oacute; adatfeldolgoz&oacute; &aacute;ltal kezelt szem&eacute;lyes adatokat helyesb&iacute;ti,
          annak t&eacute;ny&eacute;ről &eacute;s a helyesb&iacute;tett szem&eacute;lyes adatr&oacute;l
          t&aacute;j&eacute;koztatja azt az Adatkezelőt, amely r&eacute;sz&eacute;re a helyesb&iacute;t&eacute;ssel
          &Eacute;rintett szem&eacute;lyes adatot tov&aacute;bb&iacute;totta.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">3. A t&ouml;rl&eacute;shez
            val&oacute; jog (&bdquo;az elfeledtet&eacute;shez val&oacute; jog&rdquo;)</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogosult arra,
          hogy k&eacute;r&eacute;s&eacute;re az Adatkezelő indokolatlan k&eacute;sedelem n&eacute;lk&uuml;l
          t&ouml;r&ouml;lje a r&aacute; vonatkoz&oacute; szem&eacute;lyes adatokat, ha az al&aacute;bbi indokok
          valamelyike fenn&aacute;ll:</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) a szem&eacute;lyes adatokra
          m&aacute;r nincs sz&uuml;ks&eacute;g abb&oacute;l a c&eacute;lb&oacute;l, amelyből azokat
          gyűjt&ouml;tt&eacute;k vagy m&aacute;s m&oacute;don kezelt&eacute;k;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) az &Eacute;rintett visszavonja a
          szem&eacute;lyes adatok vagy a szem&eacute;lyes adatok k&uuml;l&ouml;nleges kateg&oacute;ri&aacute;i
          kezel&eacute;shez sz&uuml;ks&eacute;ges, az adatkezel&eacute;s alapj&aacute;t k&eacute;pező
          hozz&aacute;j&aacute;rul&aacute;s&aacute;t, &eacute;s az adatkezel&eacute;snek nincs m&aacute;s
          jogalapja;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">c) az &Eacute;rintett a
          saj&aacute;t helyzet&eacute;vel kapcsolatos okb&oacute;l tiltakozik az Adatkezelő vagy harmadik f&eacute;l
          jogos &eacute;rdekeinek &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez sz&uuml;ks&eacute;ges
          adatkezel&eacute;s ellen, &eacute;s nincs elsőbbs&eacute;get &eacute;lvező jogszerű ok az
          adatkezel&eacute;sre, vagy az &Eacute;rintett a tiltakozik a k&ouml;zvetlen &uuml;zletszerz&eacute;s
          &eacute;rdek&eacute;ben t&ouml;rt&eacute;nő adatkezel&eacute;s, vagy a k&ouml;zvetlen
          &uuml;zletszerz&eacute;shez kapcsol&oacute;d&oacute; profilalkot&aacute;s sor&aacute;n
          megval&oacute;sul&oacute; adatkezel&eacute;s ellen;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">d) az adatkezel&eacute;s
          jogellenes;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">e) a szem&eacute;lyes adatokat az
          Adatkezelőre alkalmazand&oacute; uni&oacute;s vagy tag&aacute;llami jogban elő&iacute;rt jogi
          k&ouml;telezetts&eacute;g teljes&iacute;t&eacute;s&eacute;hez t&ouml;r&ouml;lni kell;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">f) a szem&eacute;lyes adatok
          gyűjt&eacute;s&eacute;re k&ouml;zvetlen&uuml;l gyermekeknek k&iacute;n&aacute;lt inform&aacute;ci&oacute;s
          t&aacute;rsadalommal &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;sok k&iacute;n&aacute;l&aacute;s&aacute;val
          kapcsolatosan ker&uuml;lt sor.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">g) az adatok
          t&ouml;rl&eacute;s&eacute;t jogszab&aacute;ly, az Eur&oacute;pai Uni&oacute; jogi aktusa, a
          Hat&oacute;s&aacute;g vagy a b&iacute;r&oacute;s&aacute;g elrendelte</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">4. Az adatkezel&eacute;s
            korl&aacute;toz&aacute;s&aacute;hoz val&oacute; jog</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogosult arra,
          hogy k&eacute;r&eacute;s&eacute;re az Adatkezelő korl&aacute;tozza az adatkezel&eacute;st, ha az
          al&aacute;bbiak valamelyike teljes&uuml;l:</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) ha az &Eacute;rintett vitatja az
          Adatkezelő, illetve a megb&iacute;z&aacute;s&aacute;b&oacute;l vagy rendelkez&eacute;se alapj&aacute;n
          elj&aacute;r&oacute; adatfeldolgoz&oacute; &aacute;ltal kezelt szem&eacute;lyes adatok
          pontoss&aacute;g&aacute;t, helyt&aacute;ll&oacute;s&aacute;g&aacute;t vagy
          hi&aacute;nytalans&aacute;g&aacute;t, &eacute;s a kezelt szem&eacute;lyes adatok pontoss&aacute;ga,
          helyt&aacute;ll&oacute;s&aacute;ga vagy hi&aacute;nytalans&aacute;ga k&eacute;ts&eacute;get
          kiz&aacute;r&oacute;an nem &aacute;llap&iacute;that&oacute; meg, a fenn&aacute;ll&oacute; k&eacute;ts&eacute;g
          tiszt&aacute;z&aacute;s&aacute;nak időtartam&aacute;ra,</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) az adatkezel&eacute;s
          jogellenes, &eacute;s az &Eacute;rintett ellenzi az adatok t&ouml;rl&eacute;s&eacute;t, &eacute;s ehelyett
          k&eacute;ri azok felhaszn&aacute;l&aacute;s&aacute;nak korl&aacute;toz&aacute;s&aacute;t; vagy az
          &Eacute;rintett &iacute;r&aacute;sbeli nyilatkozata vagy az Adatkezelő rendelkez&eacute;s&eacute;re
          &aacute;ll&oacute; inform&aacute;ci&oacute;k alapj&aacute;n megalapozottan felt&eacute;telezhető, hogy az
          adatok t&ouml;rl&eacute;se s&eacute;rten&eacute; az &Eacute;rintett jogos &eacute;rdekeit, a
          t&ouml;rl&eacute;s mellőz&eacute;s&eacute;t megalapoz&oacute; jogos &eacute;rdek
          fenn&aacute;ll&aacute;s&aacute;nak időtartam&aacute;ra,</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">c) ha az adatok
          t&ouml;rl&eacute;s&eacute;nek lenne helye, de az Adatkezelő vagy m&aacute;s k&ouml;zfeladatot
          ell&aacute;t&oacute; szerv &aacute;ltal vagy r&eacute;szv&eacute;tel&eacute;vel v&eacute;gzett,
          jogszab&aacute;lyban meghat&aacute;rozott vizsg&aacute;latok vagy elj&aacute;r&aacute;sok - &iacute;gy
          k&uuml;l&ouml;n&ouml;sen b&uuml;ntetőelj&aacute;r&aacute;s - sor&aacute;n az adatok
          bizony&iacute;t&eacute;kk&eacute;nt val&oacute; megőrz&eacute;se sz&uuml;ks&eacute;ges, ezen vizsg&aacute;lat
          vagy elj&aacute;r&aacute;s v&eacute;gleges, illetve jogerős lez&aacute;r&aacute;s&aacute;ig,</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><em><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">d)&nbsp;</span></em><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">ha a 20.
          &sect;&nbsp;</span><em><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a)&nbsp;</span></em><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">pontj&aacute;ban
          meghat&aacute;rozottak szerint az adatok t&ouml;rl&eacute;s&eacute;nek lenne helye, de az Inf&oacute;
          t&ouml;rv&eacute;ny 12. &sect; (2) bekezd&eacute;s&eacute;ben foglalt dokument&aacute;ci&oacute;s
          k&ouml;telezetts&eacute;g teljes&iacute;t&eacute;se c&eacute;lj&aacute;b&oacute;l az adatok megőrz&eacute;se
          sz&uuml;ks&eacute;ges</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">e) az Adatkezelőnek m&aacute;r
          nincs sz&uuml;ks&eacute;ge a szem&eacute;lyes adatokra adatkezel&eacute;s c&eacute;lj&aacute;b&oacute;l, de az
          &Eacute;rintett ig&eacute;nyli azokat jogi ig&eacute;nyek előterjeszt&eacute;s&eacute;hez,
          &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy v&eacute;delm&eacute;hez; vagy</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">f) az &Eacute;rintett a
          saj&aacute;t helyzet&eacute;vel &ouml;sszef&uuml;ggő okb&oacute;l tiltakozott az Adatkezelő vagy harmadik
          f&eacute;l jogos &eacute;rdekeinek &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez sz&uuml;ks&eacute;ges
          adatkezel&eacute;s ellen; ez esetben a korl&aacute;toz&aacute;s arra az időtartamra vonatkozik, am&iacute;g
          meg&aacute;llap&iacute;t&aacute;sra nem ker&uuml;l, hogy az Adatkezelő jogos indokai elsőbbs&eacute;get
          &eacute;lveznek-e az &Eacute;rintett jogos indokaival szemben.</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha az adatkezel&eacute;s az előzőek
          szerint korl&aacute;toz&aacute;s al&aacute; esik, az ilyen szem&eacute;lyes adatokat a t&aacute;rol&aacute;s
          kiv&eacute;tel&eacute;vel csak az &Eacute;rintett hozz&aacute;j&aacute;rul&aacute;s&aacute;val, vagy jogi
          ig&eacute;nyek előterjeszt&eacute;s&eacute;hez, &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy
          v&eacute;delm&eacute;hez, vagy m&aacute;s term&eacute;szetes vagy jogi szem&eacute;ly jogainak v&eacute;delme
          &eacute;rdek&eacute;ben, vagy az Eur&oacute;pai Uni&oacute;, illetve valamely tag&aacute;llam fontos
          k&ouml;z&eacute;rdek&eacute;ből lehet kezelni.</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">5. Az
            adathordozhat&oacute;s&aacute;ghoz val&oacute; jog</span></strong></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogosult arra,
          hogy a r&aacute; vonatkoz&oacute;, &aacute;ltala egy Adatkezelő rendelkez&eacute;s&eacute;re bocs&aacute;tott
          szem&eacute;lyes adatokat tagolt, sz&eacute;les k&ouml;rben haszn&aacute;lt, g&eacute;ppel olvashat&oacute;
          form&aacute;tumban megkapja, tov&aacute;bb&aacute; jogosult arra, hogy ezeket az adatokat egy m&aacute;sik
          adatkezelőnek tov&aacute;bb&iacute;tsa an&eacute;lk&uuml;l, hogy ezt akad&aacute;lyozn&aacute; az az
          adatkezelő, amelynek a szem&eacute;lyes adatokat a rendelkez&eacute;s&eacute;re bocs&aacute;totta, ha&nbsp;az
          adatkezel&eacute;s hozz&aacute;j&aacute;rul&aacute;son, vagy szerződ&eacute;sen alapul &eacute;s&nbsp;az
          adatkezel&eacute;s automatiz&aacute;lt m&oacute;don t&ouml;rt&eacute;nik.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az adatok
          hordozhat&oacute;s&aacute;g&aacute;hoz val&oacute; jog gyakorl&aacute;sa sor&aacute;n az &eacute;rintett
          jogosult arra, hogy &ndash; ha ez technikailag megval&oacute;s&iacute;that&oacute; &ndash; k&eacute;rje a
          szem&eacute;lyes adatok adatkezelők k&ouml;z&ouml;tti k&ouml;zvetlen
          tov&aacute;bb&iacute;t&aacute;s&aacute;t.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">6. A tiltakoz&aacute;shoz
            val&oacute; jog</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogosult arra,
          hogy a saj&aacute;t helyzet&eacute;vel kapcsolatos okokb&oacute;l b&aacute;rmikor tiltakozzon szem&eacute;lyes
          adatainak az Adatkezelő vagy harmadik f&eacute;l jogos &eacute;rdekeinek
          &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez sz&uuml;ks&eacute;ges adatkezel&eacute;s ellen,
          ide&eacute;rtve az eml&iacute;tett rendelkez&eacute;seken alapul&oacute; profilalkot&aacute;st is. Ebben az
          esetben az Adatkezelő a szem&eacute;lyes adatokat nem kezelheti tov&aacute;bb, kiv&eacute;ve, ha az Adatkezelő
          bizony&iacute;tja, hogy az adatkezel&eacute;st olyan k&eacute;nyszer&iacute;tő erejű jogos okok
          indokolj&aacute;k, amelyek elsőbbs&eacute;get &eacute;lveznek az &Eacute;rintett &eacute;rdekeivel, jogaival
          &eacute;s szabads&aacute;gaival szemben, vagy amelyek jogi ig&eacute;nyek előterjeszt&eacute;s&eacute;hez,
          &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez vagy v&eacute;delm&eacute;hez kapcsol&oacute;dnak.</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha a szem&eacute;lyes adatok
          kezel&eacute;se k&ouml;zvetlen &uuml;zletszerz&eacute;s &eacute;rdek&eacute;ben t&ouml;rt&eacute;nik az
          &Eacute;rintett jogosult arra, hogy b&aacute;rmikor tiltakozzon a r&aacute; vonatkoz&oacute; szem&eacute;lyes
          adatok e c&eacute;lb&oacute;l t&ouml;rt&eacute;nő kezel&eacute;se ellen, ide&eacute;rtve a
          profilalkot&aacute;st is, amennyiben az a k&ouml;zvetlen &uuml;zletszerz&eacute;shez
          kapcsol&oacute;dik.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett
          t&ouml;rv&eacute;nyben meghat&aacute;rozott egy&eacute;b esetben is tiltakozhat szem&eacute;lyes adatai
          kezel&eacute;se ellen.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha az &Eacute;rintett tiltakozik a
          szem&eacute;lyes adatok k&ouml;zvetlen &uuml;zletszerz&eacute;s &eacute;rdek&eacute;ben t&ouml;rt&eacute;nő
          kezel&eacute;se ellen, akkor a szem&eacute;lyes adatok a tov&aacute;bbiakban e c&eacute;lb&oacute;l nem
          kezelhetők.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az inform&aacute;ci&oacute;s
          t&aacute;rsadalommal &ouml;sszef&uuml;ggő szolg&aacute;ltat&aacute;sok ig&eacute;nybev&eacute;tel&eacute;hez
          kapcsol&oacute;d&oacute;an &eacute;s a 2002/58/EK ir&aacute;nyelvtől elt&eacute;rve az &Eacute;rintett a
          tiltakoz&aacute;shoz val&oacute; jogot műszaki elő&iacute;r&aacute;sokon alapul&oacute; automatiz&aacute;lt
          eszk&ouml;z&ouml;kkel is gyakorolhatja.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">7. Fel&uuml;gyeleti
            hat&oacute;s&aacute;gn&aacute;l t&ouml;rt&eacute;nő panaszt&eacute;telhez val&oacute; jog</span></strong>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Magyarorsz&aacute;gon a Nemzeti
          Adatv&eacute;delmi &eacute;s Inform&aacute;ci&oacute;szabads&aacute;g Hat&oacute;s&aacute;g (NAIH)
          l&aacute;tja el a fel&uuml;gyeleti jogokat, melynek el&eacute;rhetős&eacute;gei:</span></p>
      <p>&nbsp;</p>
      <div align="left">
        <table style="border: none;">
          <colgroup>
            <col style="width: 295px;" />
            <col style="width: 310px;" />
          </colgroup>
          <tbody>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">n&eacute;v</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Nemzeti
                    Adatv&eacute;delmi &eacute;s Inform&aacute;ci&oacute;szabads&aacute;g Hat&oacute;s&aacute;g</span>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">sz&eacute;khely</span>
                </p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">1125 Budapest,
                    Szil&aacute;gyi Erzs&eacute;bet fasor 22/c.</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">postac&iacute;m</span>
                </p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">1530 Budapest, Pf.:
                    5.</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">telefonsz&aacute;m</span>
                </p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">+36 (1) 391-1400</span>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">faxsz&aacute;m</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">+36 (1) 391-1410</span>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">k&ouml;zponti
                    elektronikus lev&eacute;lc&iacute;m</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">ugyfelszolgalat@naih.hu</span>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">honlap URL-je</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><a
                    style="text-decoration: none;" href="http://www.naih.hu" rel="noopener noreferrer"><span
                      style="font-size: 11pt; font-family: Calibri, sans-serif; color: #0563c1; text-decoration: underline; text-decoration-skip-ink: none;">http://www.naih.hu</span></a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>&nbsp;</p>
      <div align="left">
        <table style="border: none;">
          <colgroup>
            <col style="width: 295px;" />
            <col style="width: 310px;" />
          </colgroup>
          <tbody>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">&uuml;gyf&eacute;lszolg&aacute;lat
                    vagy k&ouml;z&ouml;ns&eacute;gkapcsolat&nbsp; el&eacute;rhetős&eacute;ge</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Nemzeti
                    Adatv&eacute;delmi &eacute;s Inform&aacute;ci&oacute;szabads&aacute;g Hat&oacute;s&aacute;g
                    &Uuml;gyf&eacute;lszolg&aacute;lata</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">telefonsz&aacute;m</span>
                </p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">+36 (1) 391-1400</span>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">faxsz&aacute;m</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">+36 (1) 391-1410</span>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">c&iacute;m</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">1125 Budapest,
                    Szil&aacute;gyi Erzs&eacute;bet fasor 22/c.</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">postac&iacute;m</span>
                </p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">1530 Budapest, Pf.:
                    5</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">online
                    &uuml;gyint&eacute;z&eacute;s el&eacute;rhetős&eacute;ge</span></p>
              </td>
              <td style="border-width: 0.5pt; border-color: #000000; vertical-align: top;">
                <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><a
                    style="text-decoration: none;" href="http://www.naih.h/" aria-invalid="true"><span
                      style="font-size: 11pt; font-family: Calibri, sans-serif; color: #0563c1; text-decoration: underline; text-decoration-skip-ink: none;">
                      https://naih.hu/online-uegyinditas.html&nbsp;</span></a></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">&nbsp;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Minden &Eacute;rintett jogosult
          arra, hogy panaszt tegyen egy fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l &ndash;
          k&uuml;l&ouml;n&ouml;sen a szok&aacute;sos tart&oacute;zkod&aacute;si helye, a munkahelye vagy a
          felt&eacute;telezett jogs&eacute;rt&eacute;s helye szerinti tag&aacute;llamban &ndash;, ha az &Eacute;rintett
          meg&iacute;t&eacute;l&eacute;se szerint a r&aacute; vonatkoz&oacute; szem&eacute;lyes adatok kezel&eacute;se
          megs&eacute;rti e rendeletet.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Jogainak
          &eacute;rv&eacute;nyes&iacute;t&eacute;se &eacute;rdek&eacute;ben az &Eacute;rintett a</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) NAIH vizsg&aacute;lat&aacute;t
          kezdem&eacute;nyezheti az Adatkezelő int&eacute;zked&eacute;se jogszerűs&eacute;g&eacute;nek vizsg&aacute;lata
          c&eacute;lj&aacute;b&oacute;l, ha az Adatkezelő a szem&eacute;lyes adatok kezel&eacute;s&eacute;vel
          kapcsolatos jogainak &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;t korl&aacute;tozza vagy ezen jogainak
          &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;re ir&aacute;nyul&oacute; k&eacute;relm&eacute;t
          elutas&iacute;tja, valamint</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) a NAIH adatv&eacute;delmi
          hat&oacute;s&aacute;gi elj&aacute;r&aacute;s&aacute;nak lefolytat&aacute;s&aacute;t k&eacute;relmezheti, ha
          meg&iacute;t&eacute;l&eacute;se szerint szem&eacute;lyes adatainak kezel&eacute;se sor&aacute;n az Adatkezelő,
          illetve az &aacute;ltala megb&iacute;zott vagy rendelkez&eacute;se alapj&aacute;n elj&aacute;r&oacute;
          adatfeldolgoz&oacute; megs&eacute;rti a szem&eacute;lyes adatok kezel&eacute;s&eacute;re vonatkoz&oacute;,
          jogszab&aacute;lyban vagy az Eur&oacute;pai Uni&oacute; k&ouml;telező jogi aktus&aacute;ban
          meghat&aacute;rozott elő&iacute;r&aacute;sokat.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">8. B&iacute;r&oacute;s&aacute;gi
            jog&eacute;rv&eacute;nyes&iacute;t&eacute;s</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett az Adatkezelő,
          illetve - az adatfeldolgoz&oacute; tev&eacute;kenys&eacute;gi k&ouml;r&eacute;be tartoz&oacute;
          adatkezel&eacute;si műveletekkel &ouml;sszef&uuml;gg&eacute;sben - az adatfeldolgoz&oacute; ellen
          b&iacute;r&oacute;s&aacute;ghoz fordulhat, ha meg&iacute;t&eacute;l&eacute;se szerint az Adatkezelő, illetve
          az &aacute;ltala megb&iacute;zott vagy rendelkez&eacute;se alapj&aacute;n elj&aacute;r&oacute;
          adatfeldolgoz&oacute; a szem&eacute;lyes adatait a szem&eacute;lyes adatok kezel&eacute;s&eacute;re
          vonatkoz&oacute;, jogszab&aacute;lyban vagy az Eur&oacute;pai Uni&oacute; k&ouml;telező jogi aktus&aacute;ban
          meghat&aacute;rozott elő&iacute;r&aacute;sok megs&eacute;rt&eacute;s&eacute;vel kezeli.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A pert az &Eacute;rintett az
          Adatkezelő sz&eacute;khelye vagy az &Eacute;rintett lak&oacute;helye vagy tart&oacute;zkod&aacute;si helye
          szerint illet&eacute;kes t&ouml;rv&eacute;nysz&eacute;k előtt ind&iacute;thatja meg.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha a b&iacute;r&oacute;s&aacute;g a
          keresetnek helyt ad, a jogs&eacute;rt&eacute;s t&eacute;ny&eacute;t meg&aacute;llap&iacute;tja &eacute;s az
          Adatkezelőt, illetve az adatfeldolgoz&oacute;t</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) a jogellenes adatkezel&eacute;si
          művelet megsz&uuml;ntet&eacute;s&eacute;re,</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) az adatkezel&eacute;s
          jogszerűs&eacute;g&eacute;nek helyre&aacute;ll&iacute;t&aacute;s&aacute;ra, illetve</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">c) az &Eacute;rintett jogai
          &eacute;rv&eacute;nyes&uuml;l&eacute;s&eacute;nek biztos&iacute;t&aacute;s&aacute;ra pontosan
          meghat&aacute;rozott magatart&aacute;s tan&uacute;s&iacute;t&aacute;s&aacute;ra</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">k&ouml;telezi, &eacute;s
          sz&uuml;ks&eacute;g eset&eacute;n egy&uacute;ttal hat&aacute;roz a k&aacute;rt&eacute;r&iacute;t&eacute;s,
          s&eacute;relemd&iacute;j ir&aacute;nti ig&eacute;nyről is.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">AZ ADATKEZELŐ
            ELJ&Aacute;R&Aacute;SA AZ &Eacute;RINTETT FENTI JOGAI GYAKORL&Aacute;SA ESET&Eacute;N</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő az &Eacute;rintett
          &aacute;ltal beny&uacute;jtott, az őt megillető jogosults&aacute;gok
          &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;re ir&aacute;nyul&oacute; k&eacute;relmet annak
          beny&uacute;jt&aacute;s&aacute;t&oacute;l sz&aacute;m&iacute;tott legr&ouml;videbb idő alatt, de legfeljebb
          huszon&ouml;t napon bel&uuml;l elb&iacute;r&aacute;lja &eacute;s d&ouml;nt&eacute;s&eacute;ről az
          &Eacute;rintettet &iacute;r&aacute;sban vagy, ha az &Eacute;rintett a k&eacute;relmet elektronikus &uacute;ton
          ny&uacute;jtotta be, elektronikus &uacute;ton &eacute;rtes&iacute;ti.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő az &eacute;rintetti
          jogok &eacute;rv&eacute;nyes&uuml;l&eacute;s&eacute;vel kapcsolatban az e t&ouml;rv&eacute;nyben
          meghat&aacute;rozott feladatait főszab&aacute;ly szerint ingyenesen l&aacute;tja el.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő a k&eacute;relemben
          k&eacute;rt t&aacute;j&eacute;koztat&aacute;st &eacute;s int&eacute;zked&eacute;st d&iacute;jmentesen kell
          biztos&iacute;tja. Ha az &Eacute;rintett k&eacute;relme egy&eacute;rtelműen megalapozatlan vagy &ndash;
          k&uuml;l&ouml;n&ouml;sen ism&eacute;tlődő jellege miatt &ndash; t&uacute;lz&oacute;, az Adatkezelő,
          figyelemmel a k&eacute;rt inform&aacute;ci&oacute; vagy t&aacute;j&eacute;koztat&aacute;s
          ny&uacute;jt&aacute;s&aacute;val vagy a k&eacute;rt int&eacute;zked&eacute;s meghozatal&aacute;val
          j&aacute;r&oacute; adminisztrat&iacute;v k&ouml;lts&eacute;gekre &eacute;szszerű &ouml;sszegű d&iacute;jat
          sz&aacute;m&iacute;that fel, vagy megtagadhatja a k&eacute;relem alapj&aacute;n t&ouml;rt&eacute;nő
          int&eacute;zked&eacute;st.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha az &Eacute;rintett</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">a) a foly&oacute; &eacute;vben,
          azonos adatk&ouml;rre vonatkoz&oacute;an &eacute;rintetti jogai &eacute;rv&eacute;nyes&iacute;t&eacute;se
          ir&aacute;nt ism&eacute;telten k&eacute;relmet ny&uacute;jt be, &eacute;s</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">b) e k&eacute;relme alapj&aacute;n
          az Adatkezelő vagy az &aacute;ltala megb&iacute;zott vagy rendelkez&eacute;se alapj&aacute;n
          elj&aacute;r&oacute; adatfeldolgoz&oacute; &aacute;ltal kezelt szem&eacute;lyes adatainak
          helyesb&iacute;t&eacute;s&eacute;t, t&ouml;rl&eacute;s&eacute;t vagy az adatkezel&eacute;s
          korl&aacute;toz&aacute;s&aacute;t az Adatkezelő jogszerűen mellőzi,</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">az Adatkezelő az &Eacute;rintett
          &eacute;rintetti jogainak ism&eacute;telt &eacute;s megalapozatlan
          &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;vel &ouml;sszef&uuml;gg&eacute;sben k&ouml;zvetlen&uuml;l
          felmer&uuml;lt k&ouml;lts&eacute;geinek megt&eacute;r&iacute;t&eacute;s&eacute;t k&ouml;vetelheti az
          &Eacute;rintettől.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha megalapozottan feltehető, hogy
          az &eacute;rintetti jogok &eacute;rv&eacute;nyes&iacute;t&eacute;se ir&aacute;nt k&eacute;relmet
          beny&uacute;jt&oacute; szem&eacute;ly az &Eacute;rintettel nem azonos szem&eacute;ly, az Adatkezelő a
          k&eacute;relmet az azt beny&uacute;jt&oacute; szem&eacute;ly szem&eacute;lyazonoss&aacute;g&aacute;nak hitelt
          &eacute;rdemlő igazol&aacute;s&aacute;t k&ouml;vetően teljes&iacute;ti.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Ha az Adatkezelő nem tesz
          int&eacute;zked&eacute;seket az &Eacute;rintett k&eacute;relme nyom&aacute;n, k&eacute;sedelem
          n&eacute;lk&uuml;l, de legk&eacute;sőbb a k&eacute;relem be&eacute;rkez&eacute;s&eacute;től
          sz&aacute;m&iacute;tott 25 napon bel&uuml;l t&aacute;j&eacute;koztatja az &Eacute;rintettet az
          int&eacute;zked&eacute;s elmarad&aacute;s&aacute;nak okair&oacute;l, valamint arr&oacute;l, hogy az
          &Eacute;rintett panaszt ny&uacute;jthat be valamely fel&uuml;gyeleti hat&oacute;s&aacute;gn&aacute;l,
          &eacute;s &eacute;lhet b&iacute;r&oacute;s&aacute;gi jogorvoslati jog&aacute;val.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő az adatkezel&eacute;s
          t&aacute;rgy&aacute;t k&eacute;pező szem&eacute;lyes adatok m&aacute;solat&aacute;t az &Eacute;rintett
          rendelkez&eacute;s&eacute;re bocs&aacute;tja. Az &Eacute;rintett &aacute;ltal k&eacute;rt tov&aacute;bbi
          m&aacute;solatok&eacute;rt az Adatkezelő az adminisztrat&iacute;v k&ouml;lts&eacute;geken alapul&oacute;,
          &eacute;szszerű m&eacute;rt&eacute;kű d&iacute;jat sz&aacute;m&iacute;that fel. Ha az &Eacute;rintett
          elektronikus &uacute;ton ny&uacute;jtotta be a k&eacute;relmet, az inform&aacute;ci&oacute;kat sz&eacute;les
          k&ouml;rben haszn&aacute;lt elektronikus form&aacute;tumban kell rendelkez&eacute;sre bocs&aacute;tani,
          kiv&eacute;ve, ha az &Eacute;rintett m&aacute;sk&eacute;nt k&eacute;ri.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A m&aacute;solat
          ig&eacute;nyl&eacute;s&eacute;re vonatkoz&oacute; jog nem &eacute;rintheti h&aacute;tr&aacute;nyosan
          m&aacute;sok jogait &eacute;s szabads&aacute;gait.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő minden olyan
          c&iacute;mzettet t&aacute;j&eacute;koztat valamennyi helyesb&iacute;t&eacute;sről, t&ouml;rl&eacute;sről vagy
          adatkezel&eacute;s-korl&aacute;toz&aacute;sr&oacute;l, &eacute;s z&aacute;rol&aacute;s&aacute;r&oacute;l,
          akivel, illetve amellyel a szem&eacute;lyes adatot k&ouml;z&ouml;lt&eacute;k, kiv&eacute;ve, ha ez
          lehetetlennek bizonyul, vagy ar&aacute;nytalanul nagy erőfesz&iacute;t&eacute;st ig&eacute;nyel. Az
          &Eacute;rintettet k&eacute;r&eacute;s&eacute;re az Adatkezelő t&aacute;j&eacute;koztatja e
          c&iacute;mzettekről.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A t&aacute;j&eacute;koztat&aacute;s
          csak t&ouml;rv&eacute;nyben megadott esetben tagadhat&oacute; meg. A t&aacute;j&eacute;koztat&aacute;s
          megtagad&aacute;sa eset&eacute;n az Adatkezelő &iacute;r&aacute;sban k&ouml;zli az &Eacute;rintettel, hogy a
          felvil&aacute;gos&iacute;t&aacute;s megtagad&aacute;s&aacute;ra e t&ouml;rv&eacute;ny mely rendelkez&eacute;se
          alapj&aacute;n ker&uuml;lt sor. A felvil&aacute;gos&iacute;t&aacute;s megtagad&aacute;sa eset&eacute;n az
          Adatkezelő t&aacute;j&eacute;koztatja az &Eacute;rintettet a b&iacute;r&oacute;s&aacute;gi jogorvoslat,
          tov&aacute;bb&aacute; a NAIH-hoz fordul&aacute;s lehetős&eacute;g&eacute;ről.</span></p>

      <p style="line-height: 1.295; text-align: center; margin-top: 32pt; margin-bottom: 16pt;"><strong><span
            style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000; font-variant-caps: small-caps;">EGYES
            ADATKEZEL&Eacute;SEKKEL &Ouml;SSZEF&Uuml;GGŐ RENDELKEZ&Eacute;SEK</span></strong></p>

      <p style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Kapcsolatfelv&eacute;tel
            sor&aacute;n t&ouml;rt&eacute;nő adatkezel&eacute;s</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Amikor az &Eacute;rintett a
          Weboldalon tal&aacute;lhat&oacute; kapcsolati lehetős&eacute;gek egyik&eacute;n felveszi a kapcsolatot az
          Adatkezelővel, akkor az Adatkezelő az &Eacute;rintett szem&eacute;lyes adatait az al&aacute;bbi m&oacute;don
          kezeli.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A SZEM&Eacute;LYES ADATOK
          KATEG&Oacute;RI&Aacute;I - KEZELT SZEM&Eacute;LYES ADATOK K&Ouml;RE</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A kapcsolatfelv&eacute;tel
          eset&eacute;n kezelt szem&eacute;lyes adatok az al&aacute;bbiak lehetnek:</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">- n&eacute;v</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">- e-mail c&iacute;m (e-mailen
          kereszt&uuml;l t&ouml;rt&eacute;nő kapcsolatfelv&eacute;tel eset&eacute;n)</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">- telefonsz&aacute;m
          (telefonh&iacute;v&aacute;s eset&eacute;n)</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">- egy&eacute;b, az &Eacute;rintett
          &aacute;ltal a kapcsolatfelv&eacute;tel sor&aacute;n &ouml;nk&eacute;ntesen az Adatkezelő
          tudom&aacute;s&aacute;ra hozott szem&eacute;lyes adat</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A KEZELT SZEM&Eacute;LYES ADATOK
            GYŰJT&Eacute;S&Eacute;NEK FORR&Aacute;SA</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A weboldalon megadott kapcsolati
          lehetős&eacute;geken kereszt&uuml;l t&ouml;rt&eacute;nő kapcsolatfelv&eacute;tel, &iacute;gy kapcsolati űrlap,
          e-mail vagy telefon &uacute;tj&aacute;n t&ouml;rt&eacute;nő kapcsolatfelv&eacute;tel.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">ADATKEZEL&Eacute;S
            C&Eacute;LJA</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az adatkezel&eacute;s c&eacute;lja
          a kapcsolatfelv&eacute;tel &eacute;s kapcsolattart&aacute;s.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">ADATKEZEL&Eacute;S
            JOGALAPJA</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az adatkezel&eacute;s jogalapja
          kapcsolatfelv&eacute;tel eset&eacute;n a GDPR 6. cikk (1) bekezd&eacute;s a) pontja: &bdquo;az &Eacute;rintett
          hozz&aacute;j&aacute;rul&aacute;sa&rdquo;,&nbsp;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">T&aacute;j&eacute;koztatjuk, hogy
          amennyiben nem j&aacute;rul hozz&aacute; szem&eacute;lyes adatai kezel&eacute;s&eacute;hez, &uacute;gy nem
          tudja felvenni, illetve tartani a kapcsolatot az Adatkezelővel.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">AZ ADATFELDOLGOZ&Oacute;RA
            VONATKOZ&Oacute; ADATOK</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">E-mail eset&eacute;n:&nbsp;</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">N&eacute;v: GOOGLE IRELAND
          LIMITED</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Sz&eacute;khely: GORDON HOUSE,
          BARROW STREET, DUBLIN 4, DUBLIN, D04E5W5, IRELAND</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">C&eacute;gjegyz&eacute;ksz&aacute;m:
          IE368047</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">T&aacute;rhelyszolg&aacute;ltat&oacute;:</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">N&eacute;v: Servergarden
          Kft.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Sz&eacute;khely: 1139 Budapest,
          V&aacute;ci &uacute;t 99-105. Balance Building. &eacute;p. 3. em.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">C&eacute;gjegyz&eacute;ksz&aacute;m:
          01-09-350297</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">AZ ADATOK
            T&Aacute;ROL&Aacute;S&Aacute;NAK IDŐTARTAMA</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A kezelt szem&eacute;lyes adatokat
          legk&eacute;sőbb a hozz&aacute;j&aacute;rul&aacute;s visszavon&aacute;s&aacute;ig őrizz&uuml;k.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Amennyiben a
          kapcsolatfelv&eacute;telt nem k&ouml;veti tov&aacute;bbi kommunik&aacute;ci&oacute; az Adatkezelő &eacute;s az
          &Eacute;rintett k&ouml;z&ouml;tt, akkor valamennyi szem&eacute;lyes adatot az utols&oacute;
          kapcsolatfelv&eacute;telt k&ouml;vető 30 napon bel&uuml;l t&ouml;r&ouml;lj&uuml;k.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az &Eacute;rintett jogosult arra,
          hogy hozz&aacute;j&aacute;rul&aacute;s&aacute;t b&aacute;rmikor visszavonja. A
          hozz&aacute;j&aacute;rul&aacute;s visszavon&aacute;sa nem &eacute;rinti a hozz&aacute;j&aacute;rul&aacute;son
          alapul&oacute;, a visszavon&aacute;s előtti adatkezel&eacute;s jogszerűs&eacute;g&eacute;t.</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">SZEM&Eacute;LYES ADATOK
            C&Iacute;MZETTJEI</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A szem&eacute;lyes adatokhoz
          kiz&aacute;r&oacute;lag az Adatkezelő &eacute;s az Adatfeldolgoz&oacute;k r&eacute;sz&eacute;ről
          elj&aacute;rni jogosult munkav&aacute;llal&oacute;k vagy megb&iacute;zottak f&eacute;rnek hozz&aacute;.</span>
      </p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">HARMADIK ORSZ&Aacute;GBA VAGY
            NEMZETK&Ouml;ZI SZERVEZET R&Eacute;SZ&Eacute;RE T&Ouml;RT&Eacute;NŐ
            ADATTOV&Aacute;BB&Iacute;T&Aacute;S</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az Adatkezelő nem
          tov&aacute;bb&iacute;tja az adatokat harmadik orsz&aacute;gba, vagy nemzetk&ouml;zi szervezet
          r&eacute;sz&eacute;re.</span></p>

    <p style="line-height: 1.295; text-align: center; margin-top: 32pt; margin-bottom: 16pt;"><strong><span
            style="font-size: 12pt; font-family: Calibri, sans-serif; color: #000000; font-variant-caps: small-caps;">S&Uuml;TI
            T&Aacute;J&Eacute;KOZTAT&Oacute;</span></strong></p>  
      <p style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><strong><span
            style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A WEBOLDAL &Aacute;LTAL HASZN&Aacute;LT S&Uuml;TIK</span></strong></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A Weboldal
          l&aacute;togat&oacute;inak sz&aacute;m&iacute;t&oacute;g&eacute;p&eacute;t &uacute;n. &bdquo;cookie-val vagy
          &bdquo;s&uuml;tivel&rdquo; azonos&iacute;tjuk.&nbsp;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A s&uuml;tik olyan kism&eacute;retű
          sz&ouml;vegf&aacute;jlok, melyeket a sz&aacute;m&iacute;t&oacute;g&eacute;p &eacute;s a b&ouml;ng&eacute;sző
          elment. Ennek megfelelően a Weboldal egyes r&eacute;szeinek let&ouml;lt&eacute;sekor s&uuml;tik ker&uuml;lnek
          az &Eacute;rintett g&eacute;p&eacute;n elhelyez&eacute;sre, melynek megt&ouml;rt&eacute;nt&eacute;ről az
          &Eacute;rintett az &uuml;zemeltetőtől nem kap tov&aacute;bbi &eacute;rtes&iacute;t&eacute;st.&nbsp;</span></p>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">A s&uuml;tik egy r&eacute;sze az
          oldal műk&ouml;d&eacute;s&eacute;hez elengedhetetlen&uuml;l sz&uuml;ks&eacute;ges (funkcion&aacute;lis
          s&uuml;tik), m&aacute;s r&eacute;sz&uuml;k k&eacute;nyelmi funkci&oacute;t szolg&aacute;l (k&eacute;nyelmi
          s&uuml;tik). Ezen fel&uuml;l l&eacute;teznek un. statisztikai elemző s&uuml;tik, melyek az Adatkezelő
          r&eacute;sz&eacute;re ny&uacute;jtanak inform&aacute;ci&oacute;t anonim form&aacute;ban a Weboldal
          l&aacute;togatotts&aacute;g&aacute;val kapcsolatban. Az Adatkezelő &aacute;ltal haszn&aacute;lt elemző
          s&uuml;tik kiz&aacute;r&oacute;lag anonim m&oacute;don gyűjtenek adatokat, azok nem alkalmasak a Weboldalt
          l&aacute;togat&oacute;k k&ouml;zvetlen vagy k&ouml;zvetett azonos&iacute;t&aacute;s&aacute;ra.</span></p>
      <p style="line-height: 1.295; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Az adatkezelő &aacute;ltal
          haszn&aacute;lt s&uuml;tik az al&aacute;bbiak:</span></p>
      <div align="left">
        <app-table></app-table>
      </div>
      <p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
          style="font-size: 11pt; font-family: Calibri, sans-serif; color: #000000;">Hat&aacute;lyos 2022.01.01.
          napj&aacute;t&oacute;l</span></p>
    </div>
  </div>
</section>