import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
@Injectable({ providedIn: 'root' })
export class LanguageResolver {
  constructor(private service: TextsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.service.loadLang(this.service.actLang);
  }
}