<div  class="holder">
  <div  #swiper class="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide"><app-slide class="swiper-slide invert" [logo]="'/assets/logos/molar_logo.webp'" [mainText]="textService.getText('referencia_project_3_leiras')" [subText]="textService.getText('referencia_project_3_leiras_sub')" [list]="textService.getText('referencia_project_3_funkciok')"></app-slide></div>
      <div class="swiper-slide"><app-slide class="swiper-slide" [logo]="'/assets/logos/ceedback_logo.webp'" [mainText]="textService.getText('referencia_project_1_leiras')" [subText]="textService.getText('referencia_project_1_leiras_sub')" [list]="textService.getText('referencia_project_1_funkciok')"></app-slide></div>
      <div class="swiper-slide"><app-slide class="swiper-slide" [logo]="'/assets/logos/celearning_logo.webp'" [mainText]="textService.getText('referencia_project_2_leiras')" [subText]="textService.getText('referencia_project_2_leiras_sub')" [list]="textService.getText('referencia_project_2_funkciok')"></app-slide></div>
      <div class="swiper-slide"><app-slide class="swiper-slide qb" [logo]="'/assets/logos/QB_logo_2.webp'" [mainText]="textService.getText('referencia_project_4_leiras')" [subText]="textService.getText('referencia_project_4_leiras_sub')" [list]="textService.getText('referencia_project_4_funkciok')"></app-slide></div>      
    </div>    
  </div>
  <div class="swiper-pagination" #pagination></div>
</div>
