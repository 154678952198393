<section id="info" class="info-part">

    <div class="background-shape"></div>

    <img class="left-dot" src="/assets/backgrounds/left-dot.webp" alt="background">
    <img class="right-dot" src="/assets/backgrounds/right-dot.webp" alt="background">
    <div class="content">

        <div class="item">
            <img src="/assets/images/ikon-weboldal.webp" alt="icon-webpage">
            <div class="texts">
                <h2>{{textService.getText('info_web_cim')}}</h2>
                <div class="text-1">{{textService.getText('info_web_leriras_1')}}</div>
                <div class="text-2" [innerHTML]="textService.getText('info_web_leriras_2')"></div>
                <ul>
                    <li *ngFor="let item of textService.getText('info_web_felsorolas').split(';')">
                       {{item}}
                    </li>
                 </ul>
                 <div class="cubes">
                     <div class="cube">
                         <img src="/assets/technologies/mysql.webp" alt="mysql">
                     </div>
                     <div class="cube">
                         <img src="/assets/technologies/oracle.webp" alt="oracle">
                     </div>
                     <div class="cube">
                         <img src="/assets/technologies/angular.webp" alt="angular">
                     </div>
                     <div class="cube">
                         <img src="/assets/technologies/laravel.webp" alt="laravel">
                     </div>      
                 </div>
            </div>
        </div>

        <hr>

        <div class="item reverse">
            <img src="/assets/images/ikon-mobil.webp" alt="icon-moblie">
            <div class="texts">
                <h2>{{textService.getText('info_mobil_cim')}}</h2>                
                <div class="text-1">{{textService.getText('info_mobil_leriras_1')}}</div>
                <div class="text-2">{{textService.getText('info_mobil_leriras_2')}}</div>
                 <div class="cubes">
                     <div class="cube">
                         <img src="/assets/technologies/android.webp" alt="android">
                     </div>
                     <div class="cube">
                         <img src="/assets/technologies/ios.webp" alt="ios">
                     </div>   
                     <div class="cube">
                         <img src="/assets/technologies/flutter.webp" alt="flutter">
                     </div>   
                 </div>
            </div>
        </div>

        <hr>

        <div class="item">
            <img src="/assets/images/ikon-uzemeltetes.webp" alt="icon-server">
            <div class="texts">
                <h2>{{textService.getText('info_uzemeltetes_cim')}}</h2>
                <div class="text-1">{{textService.getText('info_uzemeltetes_leriras_1')}}</div>
                <div class="text-2">{{textService.getText('info_uzemeltetes_leriras_2')}}</div>
                 <div class="cubes">
                     <div class="cube">
                         <img src="/assets/technologies/linux.webp" alt="linux">
                     </div>
                     <div class="cube">
                         <img src="/assets/technologies/ubuntu.webp" alt="ubuntu">
                     </div>   
                     <div class="cube">
                         <img src="/assets/technologies/docker.webp" alt="docker">
                     </div>   
                 </div>
            </div>
        </div>

        <hr>

        <div class="item reverse">
            <img src="/assets/images/ikon-seo.webp" alt="icon-wseo">
            <div class="texts">
                <h2>{{textService.getText('info_seo_cim')}}</h2>
                <div class="text-1">{{textService.getText('info_seo_leriras_1')}}</div>
                <div class="text-2">{{textService.getText('info_seo_leriras_2')}}</div>
                 <div class="cubes">
                     <div class="cube">
                         <img src="/assets/technologies/analitics.webp" alt="analitics">
                     </div>
                     <div class="cube">
                         <img src="/assets/technologies/search-console.webp" alt="search-console">
                     </div>   
                 </div>
            </div>
        </div>

        <hr>

        <div class="item">
            <img src="/assets/images/ikon-design.webp" alt="icon-design">
            <div class="texts">
                <h2>{{textService.getText('info_design_cim')}}</h2>
                <div class="text-1">{{textService.getText('info_design_leriras_1')}}</div>
                <div class="text-2">{{textService.getText('info_design_leriras_2')}}</div>
                 <div class="cubes">
                     <div class="cube">
                         <img src="/assets/technologies/ps.webp" alt="ps">
                     </div>
                     <div class="cube">
                         <img src="/assets/technologies/figma.webp" alt="figma">
                     </div>   
                     <div class="cube">
                         <img src="/assets/technologies/zeplin.webp" alt="zeplin">
                     </div>   
                 </div>
            </div>
        </div>
    </div>

</section>