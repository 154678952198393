import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

const base = [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'fixed',
        top: 0,
        left: 0,            
        width: '100%',
        height:'100vh',      
        'z-index':0           
      })
    ]),
];

const noAnimation = [
    group([
        query('*', animateChild(), {optional:true}),
    ])
 
]

const topUp = [
    ...base,
    query(':leave', [
        style({        
            opacity: 0.6
        }),
    ]),
    query(':enter', [
        style({
            top: '80vh', 
            opacity: 0.7,
            'z-index':11,
        }),
    ]),    
    group([
        query(':leave', [
            animate('600ms ease-in', style({
                 top: '-100vh',    
                 opacity: 0                 
            })),
        ]),
        query(':enter', [
            animate('600ms ease-in', style({
                 top: '0',   
                 opacity: 1            
            })),
        ]),
    ]),
];

const topDown = [
    ...base,
    query(':leave', [
        style({
            opacity: 0.6
        }),
    ]),  
    query(':enter', [
        style({
            top: '-80vh', 
            opacity: 0.7,
            'z-index':11,
        }),
    ]),  
    group([
        query(':enter', [
            animate('600ms ease-in', style({
                 top: '0',   
                 opacity: 1            
            })),
        ]),
        query(':leave', [
            animate('600ms ease-in', style({
                 top: '100vh',
                 opacity: 0 
            })),
        ]),
    ]),
];

// Fade Animation
export const routeAnimations = trigger('routeAnimations', [
    transition('void => *',noAnimation),
    transition('Home => *',topUp),
    transition('* => Home',topDown),
    transition('Info => *',topUp),
    transition('* => Info',topDown),
    transition('Reference => *',topUp),
    transition('* => Reference',topDown),
    transition('Contact => *',topUp),
    transition('* => Contact',topDown),
]);
