import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import SwiperCore, { Pagination, Swiper } from "swiper";

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements OnInit, AfterViewInit {
  swiper:Swiper;

  constructor(public textService: TextsService) { }
  ngAfterViewInit(): void {    
    this.initSwiper();

    this.textService.onLanguage().subscribe(()=>{
      this.initSwiper();
    });
  }

  ngOnInit() {  }

  initSwiper(){
    this.swiper?.destroy();
    this.swiper = new Swiper('.swiper',{
      spaceBetween:30,
      pagination:{
        clickable:true,
        el:'.swiper-pagination'
      },
      autoplay:{
        delay: 2500,
        disableOnInteraction: false
      },
      autoHeight:true,
      loop:true
    })
  }
}
