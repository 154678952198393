import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  services: {[key:string] : CookieFunction}
    
  constructor() {
    this.services = {};
    this.services[CookieTypes.Analytics] = new AnalyticsCookieFunction();
  }

  public setAll(state:boolean){
    for(var item in this.services){
      this.services[item].setState(state);
    }
  }
}

const enum CookieTypes {
  Analytics = 'analytics_enabled'
}

abstract class CookieFunction{
  type:string;

  constructor(type:string){   
    this.type = type;
    this.init(this.getState());
  }

  private init(state:boolean){
    if(state){
      this.allow();
    }else{
      this.block();
    }
  }

  public setState(state:boolean){
    localStorage.setItem(this.type,state ? '1' : '0');
    this.init(state);
  }

  public getState():boolean{      
    return localStorage.getItem(this.type) == '1';
  }

  protected abstract allow():void
  protected abstract block():void
}

export class AnalyticsCookieFunction extends CookieFunction {

  constructor(){    
    super(CookieTypes.Analytics)
  }

  allow() {    
    window['ga-disable-'+environment.analyticsKey] = false;
  }

  block() {    
    window['ga-disable-'+environment.analyticsKey] = true;
  }
  
}
