import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BlogGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {

    var urlpart1 = state.url.split('#');
    var urlpart2 = urlpart1[0].split('/');
    var lang = urlpart2[1] ?? '';
 
    if(lang == 'hu'){            
        return true;
    }else{
        return this.router.parseUrl('/'+lang);
    } 
  }  
}