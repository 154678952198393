import { animate, state, style, transition, trigger } from "@angular/animations";

export const fadeIn = trigger('fadeIn',[
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'translateY(-20px)'  
      }),
      animate('500ms',style({
        opacity: 1,      
        transform: 'translateY(0px)'   
      })),
    ]),
]);
export const fadeInButton = trigger('fadeInButton',[
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'translateY(-20px)'  
      }),
      animate('200ms 300ms',style({
        opacity: 1,      
        transform: 'translateY(0px)'   
      })),
    ]),
]);

export const fadeInMenu = trigger('fadeIMenu',[
  transition(':enter', [
    style({
      opacity: 0,
      top: '-20px'  
    }),
    animate('500ms 500ms',style({
      opacity: 1,      
      top: '0px'   
    })),
  ]),
]);

export const toggleMenuButton = trigger('toggleMenuButton',[
  state('close',style({})),
  state('open',style({})),
  transition('open <=> close',[ animate('500ms')]) 
]);

export const toggleMenuBackground = trigger('toggleMenuBackground',[
  state('close',style({

  })),
  state('open',style({
    bottom:0,
    opacity: 0.95, 
  })),  
  transition('open <=> close',[ animate('500ms ease-out')]) 
]);

export const toggleMenuElements = trigger('toggleMenuElements',[
  state('close',style({})),
  state('open',style({
    order:3,
    display:'block',
    'flex-basis':"100%",
    'margin-top':'48px'
  })),
  transition('open <=> close',[ animate('500ms')]) 
]);