import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { routeAnimations } from 'src/app/animation/page';
import { NavigationService } from 'src/app/models/services/navigation-service/navigation.service';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  animations:[routeAnimations]
})
export class DefaultLayoutComponent implements OnInit {

  @ViewChild('wrapper') elem:ElementRef;

  constructor(private navigator:NavigationService, private meta: Meta,private texts: TextsService) { 
    this.meta.updateTag({ property:"og:title", content: 'Sidequest' });
    this.meta.updateTag({ property:"og:url", content: document.URL });    
    this.meta.updateTag({ property:"og:description", content: this.texts.getText("meta_description_home") });
    this.meta.updateTag({ name:"description", content: this.texts.getText("meta_description_home") });
  }
  
  ngOnInit(): void {
    this.onPageScroll();
  }

  @HostListener('window:scroll', ['$event'])
  onPageScroll(){  
      
    const top = document.querySelector('html').scrollTop;

    if((document.querySelector('#contact') as HTMLElement).offsetTop - 72 <= top){      
      this.navigator.setActiveItem('contact'); 
    } else if((document.querySelector('#references') as HTMLElement).offsetTop - 72 <= top){      
      this.navigator.setActiveItem('references'); 
    } else if((document.querySelector('#team') as HTMLElement).offsetTop - 100 <= top){      
      this.navigator.setActiveItem('team'); 
    } else if((document.querySelector('#info') as HTMLElement).offsetTop - 72 <= top){      
      this.navigator.setActiveItem('info');
    } else {
      this.navigator.setActiveItem('cover');
    }
  }
}
