<div class="block"></div>
<section>
  <div class="head">
    <div class="content">
      <div>
        <h1>{{textService.getText('impresszum')}}</h1>
      </div>
      <hr>
    </div>
  </div>
  <div class="body">
    <div class="content">
      <p>{{textService.getText('impresszum_cegnev')}}: Sidequest Kft.</p>
      <p>{{textService.getText('impresszum_szekhely')}}: 1191 Budapest, Rákóczi utca 27. 1. em. 3.</p>
      <p>{{textService.getText('impresszum_emali')}}: info@sidequest.hu</p>
      <p>{{textService.getText('impresszum_weboldal')}}: sidequest.hu</p>
      <p>{{textService.getText('impresszum_hatosag')}}: Fővárosi Törvényszék Cégbírósága</p>
      <p>{{textService.getText('impresszum_cegjegytekszam')}}: 01-09-377242</p>
      <p>{{textService.getText('impresszum_adoszam')}}: 28788490-2-43</p>
      <p>{{textService.getText('impresszum_kamara')}}: Budapesti Kereskedelmi és Iparkamara</p>
      <p>{{textService.getText('impresszum_tarhelyszolgatato')}}: Servergarden Kft. (1139 Budapest, Váci út 99-105. Balance Building. ép. 3. em.)</p>
    </div>
  </div>
</section>