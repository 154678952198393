import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, throwError } from 'rxjs';


@Component({
  selector: 'app-contacts-part',
  templateUrl: './contacts-part.component.html',
  styleUrls: ['./contacts-part.component.scss']
})
export class ContactsPartComponent implements OnInit {

  contactForm: FormGroup;  
  key = environment.recaptchaSiteKey;
  captchaRef: any;

  devKey = "03AGdBq26ju40cv_XEW_zN8KXXFtCeXgo3Y9O3y3S8VqvP3d98n6J81j75DPFACPKnjNYcNVA4cw2J5bteq_zdo90SW8lKdgoRceMvbReEjU7g5od_R5Ep6pDHy-YA3w6fhtyU4bERg30Svw0pICqm4bTSFOcNTRfV7BoyXKSLpsj6FYF9BaImlCj1en9hNrM434BunSqgfVUQ5PkVXW2rgnaaVCvQ5GoQ8osLtHya8jbp_Pc52FcTyrLRClj10e6_EIH-3lgRqM9aOXR8eq0wwFpcQkhezBQsvtYvQ5VSEKpouVWVd8r4heUIdW-bYnbkdBE9x40hl9D2_lrLM96cpXFXpyanBauzsy3B1_W1rAeYZ9Edk-8Jioe3FQ2lr6O5JeGLVMXq3Gq1YHukUccT0QMh1yQS9EIYEpuhM8UdjTl_YXpheK7qcXfnN0oyPBcJHDZamjNcWwT60NAE2ro9gSkPDzaGHMR37Q";

  constructor(
    public textService: TextsService, 
    private formBuilder: FormBuilder,     
    private httpClient:HttpClient,
    private snackBar: MatSnackBar
  ) {

  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', {validators:Validators.required, updateOn: "blur" }],
      email: ['', {validators:[Validators.required, Validators.email], updateOn: "blur" }],
      phone: ['', {validators:Validators.required, updateOn: "blur" }],
      message: ['', {validators:Validators.required, updateOn: "blur" }],
      option: ['E',Validators.required],
      dataprotect: ['',Validators.required],
    });
    
    this.contactForm.get("email").enable();
    this.contactForm.get("phone").disable();

    this.contactForm.get("option").valueChanges.subscribe(x => {
      if(x == 'E'){
        this.contactForm.get("email").enable();
        this.contactForm.get("phone").disable();
      }else{
        this.contactForm.get("email").disable();
        this.contactForm.get("phone").enable();
      }
    });
  }


  onSubmit(captchaRef) {
    this.captchaRef = captchaRef;
    this.contactForm.markAllAsTouched();
    if(this.contactForm.valid) {
      if(environment.recaptchaSiteKey == '' && !environment.production){
        this.sendFrom(this.devKey);
      }else{
        captchaRef.execute();
      }
    }
  }

  onError(error){
    this.snackBar.open(this.textService.getText('kapcsolat_form_error'),'', {
      duration: 3000
    });
  }

  sendFrom(token:string){
    if(token == null){
      return;
    }

    const formData = new FormData();
    formData.set('name',this.contactForm.get('name').value);    
    formData.set('message',this.contactForm.get('message').value);
    formData.set('recaptcha_response',token);

    var endpoint = "";

    if(this.contactForm.get('option').value == 'E'){
      formData.set('email',this.contactForm.get('email').value);  
      endpoint = "contact-email";
    }else{
      formData.set('phone',this.contactForm.get('phone').value);
      endpoint = "contact-phone";
    }

    this.httpClient.post(environment.apiUrl+'/api/'+endpoint,formData,{observe: 'body',responseType: 'json'})
    .pipe(catchError((error: HttpErrorResponse) => {
      this.snackBar.open(this.textService.getText('kapcsolat_form_error'),'', {
        duration: 3000
      });
  
      return throwError(() => new Error('NET:ERR'));
    }))
    .subscribe((response:any) =>{
      this.captchaRef.reset();
      if(response.success){
        this.contactForm.reset();
        this.contactForm.get('option').setValue('E');
        this.snackBar.open(this.textService.getText('kapcsolat_form_succes'),'', {
          duration: 3000
        });
      }else{
        this.snackBar.open(this.textService.getText(response.errors[0].extra.email != undefined ? 'kapcsolat_form_error_email2': 'kapcsolat_form_error'),'', {
          duration: 3000
        });
      }      
    });
  }

}
