import { Component, OnInit } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';


@Component({
  selector: 'app-references-part',
  templateUrl: './references-part.component.html',
  styleUrls: ['./references-part.component.scss'],  

})
export class ReferencesPartComponent implements OnInit {

  constructor(public textService: TextsService) { }

  ngOnInit(): void {

  }

}
