import { BlogService } from 'src/app/models/services/blog-service/blog.service';
import { Blog } from 'src/app/models/services/blog-service/blog';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';

@Component({
  selector: 'app-blog-page-part',
  templateUrl: './blog-page-part.component.html',
  styleUrls: ['./blog-page-part.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogPagePartComponent implements OnInit {

  blog:Blog;
  content:string;
  
  constructor(blogs:BlogService, router: ActivatedRoute,public textService: TextsService) { 
    blogs.getPost(router.snapshot.url[0].path).subscribe((blog) => {
      this.blog = blog;

      blogs.getPostHtml(blog.contentUrl).subscribe((content) =>{
        this.content = content;
      });   
    });    
  }

  ngOnInit() {
  }

}
