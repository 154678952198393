import { Component, OnInit} from '@angular/core';
import { NavigationService } from 'src/app/models/services/navigation-service/navigation.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  constructor(private navigator:NavigationService) { }

  ngOnInit() {
    this.navigator.setActiveItem('');
  }
}
