import { LanguageResolver } from './models/resolvers/language.resolver';
import { TextComponent } from './layouts/text/text.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { PAGEPARTS } from './models/constants';
import { LanguageGuard } from './models/guards/language.guard';
import { PrivacyPartComponent } from './components/sub-pages/privacy-part/privacy-part.component';
import { ImpressumPartComponent } from './components/sub-pages/impressum-part/impressum-part.component';
import { BlogPartComponent } from './components/sub-pages/blog-part/blog-part.component';
import { BlogPagePartComponent } from './components/sub-pages/blog-page-part/blog-page-part.component';
import { BlogGuard } from './models/guards/blog.guard';

const routes: Routes = [
  {
    path: ':lang',
    canActivate: [LanguageGuard],
    resolve:[LanguageResolver],
    children: [
      {
        path:PAGEPARTS.IMPRESSUM,
        component: TextComponent,   
        children: [
          {
            path:'',
            component:ImpressumPartComponent
          },
        ]     
      },
      {
        path:PAGEPARTS.PRIVACY,
        component: TextComponent,   
        children: [
          {
            path:'',
            component:PrivacyPartComponent
          },
        ]     
      },
      {
        path:PAGEPARTS.BLOG,
        component: TextComponent,   
        canActivate: [BlogGuard],        
        children: [
          {
            path:'avdh-hitelesites',
            component:BlogPagePartComponent
          },
          {
            path:'',
            component:BlogPartComponent,            
          },

        ]     
      },
      {
        path: "",
        component: DefaultLayoutComponent,
      },
      {
        path: "**", redirectTo: '/xx', pathMatch: 'full'
      }
    ]
  },
  { path: '', redirectTo: '/xx', pathMatch: 'full' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    // anchorScrolling: 'enabled' ,
    // scrollPositionRestoration: 'top',
    // onSameUrlNavigation:'reload',
    // scrollOffset: [0,72]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
