import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
  constructor(private router: Router,private texts: TextsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {

    var urlpart1 = state.url.split('#');
    var urlpart2 = urlpart1[0].split('/');
    var lang = urlpart2[1] ?? '';
    var page = urlpart1[0].substring(4) ?? '';
    var fragment = urlpart1[1] ?? '';    

    //ha nem jó a nyelv akkor süti vagy első lesz
    if(this.texts.languages[lang] == undefined){
        let keys = Object.keys(this.texts.languages);
        let localLang = localStorage.getItem(environment.langStorageKey);        
        lang = this.texts.languages[localLang] != undefined ? localLang : this.texts.languages[keys[0]];
    }

    var newPath = '/'+ lang + (page != '' ? ('/'+page) : '') + (fragment != '' ? ('#'+fragment) : '');

    if(newPath == state.url){            
        this.texts.setPageLanguage(lang);
        return true;
    }else{
        return this.router.parseUrl(newPath);
    } 
  }

  
}