<nav 
@fadeIMenu 
[@toggleMenuBackground]="state" 
*ngIf="!navigationService.navState" [attr.theme]="'alt'">
    <div class="nav-content">
        <div class="logo">
            <img [routerLink]="'/'+textService.actLang" src="assets/icons/logo.webp" alt="logo"> 
            <div [routerLink]="'/'+textService.actLang">
                <span class="white">SIDE</span><span class="orange">QUEST</span>
            </div>
        </div>
        <div class="navbar" [@toggleMenuElements]="state">            
            <div class="nav-item"><span [ngClass]="{'active': navigationService.activeItem == 'cover'}" (click)="onMenuItem($event,'cover')">{{textService.getText('nav_fooldal')}}</span></div>
            <div class="nav-item"><span [ngClass]="{'active': navigationService.activeItem == 'info'}" (click)="onMenuItem($event,'info')">{{textService.getText('nav_mit_csinalunk')}}</span></div>
            <div class="nav-item"><span [ngClass]="{'active': navigationService.activeItem == 'team'}" (click)="onMenuItem($event,'team')">{{textService.getText('nav_rolunk')}}</span></div>
            <div class="nav-item"><span [ngClass]="{'active': navigationService.activeItem == 'references'}" (click)="onMenuItem($event,'references')">{{textService.getText('nav_referenciak')}}</span></div>            
            <div class="nav-item"><span [ngClass]="{'active': navigationService.activeItem == 'contact'}" (click)="onMenuItem($event,'contact')">{{textService.getText('nav_kapcsolat')}}</span></div>
            <div class="nav-item">
                <div class="lang">
                    <img mat-icon-button [matMenuTriggerFor]="menu" [src]="'assets/icons/'+textService.actLang+'.svg'" alt="language">
                </div>
                <mat-menu #menu="matMenu">
                    <div class="menu-item" mat-menu-item *ngFor="let item of langs" (click)="onLangSelect($event,item)">
                        <img [src]="'assets/icons/'+item+'.svg'" [alt]="item"><span>{{item}}</span>                        
                    </div>
                </mat-menu>
            </div>
            
        </div>
        <div class="mobil-menu" (click)="onMenu()">
            <img src="assets/icons/menu.webp" alt="menu">
        </div>
    </div>
</nav>
