<section id="references" class="references-part">

    <div class="background-shape"></div>

    <div class="content">
        <h2>{{textService.getText('referencia_cim')}}</h2>  
        <hr>

        <app-slider></app-slider>

        <div class="cubes">    
            <div class="outer-cube">
                <a href="https://freezairspacer.com" target="_blank" rel="noopener" title="FreezAir Spacer">
                    <div class="cube">
                        <img src="/assets/logos/freezeair_logo.webp" alt="FreezAir Spacer">                                                                   
                    </div>
                </a>
            </div>
            <!-- <div class="outer-cube">
                <a href="http://fixsteprendszer.hu/" target="_blank" rel="noopener" title="FIXSTEP ">
                    <div class="cube">
                        <img src="/assets/logos/fixstep_logo.webp" alt="FIXSTEP">                                                                   
                    </div>
                </a>
            </div> -->
        </div>
    </div>

</section>