import { fadeInMenu, toggleMenuBackground, toggleMenuElements } from './../../animation/fade';
import { Component, HostListener, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NavigationService } from 'src/app/models/services/navigation-service/navigation.service';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    fadeInMenu,
    toggleMenuBackground,
    toggleMenuElements,
  ]
})
export class NavigationComponent implements OnInit,AfterViewInit {

  state = 'close';
  opacity = '0';
  tmp:any;
  @ViewChild('cover') cover:ElementRef;
  langs:string[] = [];

  constructor(public textService: TextsService, public navigationService: NavigationService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit(): void {    
    this.langs = Object.keys(this.textService.getLangs);
  }

  ngAfterViewInit(): void {    
    setTimeout(()=>{
      this.navigationService.scrollTo(this.router.url.split('#')[1] ?? '')
    },500);
  }

  onMenu(){    
    this.state = this.state == 'open' ? 'close' : 'open';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;    
    if(event.target.innerWidth > 768){
      this.state = 'close';
    }
  }

  onMenuItem($event,fragment:string,page = ''){
    this.state = 'close';       
    this.router.navigate(['/'+this.textService.actLang+'/'+page], { fragment:fragment});
    this.navigationService.scrollTo(fragment);        
  }

  onLangSelect($event,lang:string){       
    if(lang != this.textService.actLang){ 
      localStorage.setItem(environment.langStorageKey,lang);     
      this.router.navigate([this.router.url.replace('/'+this.textService.actLang,'/'+lang)]);
    }  
  }

}
