import { BlogService } from './../../../models/services/blog-service/blog.service';
import { Component, OnInit } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';

@Component({
  selector: 'app-blog-part',
  templateUrl: './blog-part.component.html',
  styleUrls: ['./blog-part.component.scss']
})
export class BlogPartComponent implements OnInit {

  constructor(public textService: TextsService,public blogs:BlogService) {

  }

  ngOnInit() {

  }

}
