<section id="contact" class="contacts-part">
  <div class="background-shape"></div>

  <div class="content">
    <div>
      <h2>{{ textService.getText("kapcsolat_cim") }}</h2>
    </div>
    <div>
      <p
        class="bold"
        [innerHTML]="textService.getText('kapcsolat_oldal_ideiglenes_szoveg')"
      ></p>
    </div>

    <!--<form [formGroup]="contactForm" (ngSubmit)="onSubmit(captchaRef)">
      <h2>{{ textService.getText("kapcsolat_cim") }}</h2>
      <hr />
      <div class="sub">{{ textService.getText("kapcsolat_oldal_szoveg") }}</div>

      <input
        type="text"
        [placeholder]="textService.getText('placeholder_nev')"
        formControlName="name"
      />
      <div
        class="error"
        *ngIf="
          contactForm.get('name')?.invalid &&
          (contactForm.get('name')?.dirty || contactForm.get('name')?.touched)
        "
      >
        <div
          *ngIf="contactForm.get('name')?.errors?.['required']"
          class="error-message text-center"
        >
          {{ textService.getText("kapcsolat_form_error_ures") }}
        </div>
      </div>

      <div class="choose">
        <mat-radio-group formControlName="option">
          <mat-radio-button [checked]="true" value="E">{{
            textService.getText("kapcsolat_form_emailben")
          }}</mat-radio-button>
          <mat-radio-button value="P">{{
            textService.getText("kapcsolat_form_telefonon")
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="contactForm.get('email').enabled">
        <input
          type="text"
          [placeholder]="textService.getText('placeholder_email')"
          formControlName="email"
        />
        <div
          class="error"
          *ngIf="
            contactForm.get('email')?.invalid &&
            (contactForm.get('email')?.dirty ||
              contactForm.get('email')?.touched)
          "
        >
          <div
            *ngIf="contactForm.get('email')?.errors?.['required']"
            class="error-message text-center"
          >
            {{ textService.getText("kapcsolat_form_error_ures") }}
          </div>
          <div
            *ngIf="contactForm.get('email')?.errors?.['email']"
            class="error-message text-center"
          >
            {{ textService.getText("kapcsolat_form_error_email") }}
          </div>
        </div>
      </div>

      <div *ngIf="contactForm.get('phone').enabled">
        <input
          type="text"
          [placeholder]="textService.getText('placeholder_telefon')"
          formControlName="phone"
        />
        <div
          class="error"
          *ngIf="
            contactForm.get('phone')?.invalid &&
            (contactForm.get('phone')?.dirty ||
              contactForm.get('phone')?.touched)
          "
        >
          <div
            *ngIf="contactForm.get('phone')?.errors?.['required']"
            class="error-message text-center"
          >
            {{ textService.getText("kapcsolat_form_error_ures") }}
          </div>
        </div>
      </div>

      <textarea
        type="text"
        [placeholder]="textService.getText('placeholder_uzenet')"
        formControlName="message"
      ></textarea>
      <div
        class="error"
        *ngIf="
          contactForm.get('message')?.invalid &&
          (contactForm.get('message')?.dirty ||
            contactForm.get('message')?.touched)
        "
      >
        <div
          *ngIf="contactForm.get('message')?.errors?.['required']"
          class="error-message text-center"
        >
          {{ textService.getText("kapcsolat_form_error_ures") }}
        </div>
      </div>

      <div class="protect">
        <mat-checkbox formControlName="dataprotect"
          >{{ textService.getText("kapcsolat_form_adatvedelmi").split("#")[0]
          }}<a
            [href]="'/' + textService.actLang + '/privacy-policy'"
            target="_blank"
            >{{
              textService.getText("kapcsolat_form_adatvedelmi").split("#")[1]
            }}</a
          >{{
            textService.getText("kapcsolat_form_adatvedelmi").split("#")[2]
          }}</mat-checkbox
        >
      </div>
      <div
        class="error"
        *ngIf="
          contactForm.get('dataprotect')?.invalid &&
          (contactForm.get('dataprotect')?.dirty ||
            contactForm.get('dataprotect')?.touched)
        "
      >
        <div
          *ngIf="contactForm.get('dataprotect')?.errors?.['required']"
          class="error-message text-center"
        >
          {{ textService.getText("kapcsolat_form_error_ures") }}
        </div>
      </div>

      <div>
        {{ textService.getText("google_terms_part_1") }}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >{{ textService.getText("adatvedelmi_iranyelvek") }}
        </a>
        {{ textService.getText("google_terms_part_2") }}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          >{{ textService.getText("aszf") }}</a
        >
        {{ textService.getText("google_terms_part_3") }}
      </div>

      <div class="button-holder">
        <button type="submit">
          {{ textService.getText("kapcsolat_bekuldes") }}
        </button>
      </div>
      <re-captcha
        #captchaRef="reCaptcha"
        (resolved)="sendFrom($event)"
        (error)="onError($event)"
        errorMode="handled"
        size="invisible"
      ></re-captcha>
    </form>-->
  </div>
</section>
