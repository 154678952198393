import { NavigationService } from 'src/app/models/services/navigation-service/navigation.service';
import { Component, OnInit } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';
import { CookieComponent } from '../cookie/cookie.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public textService: TextsService,private navigationService: NavigationService,private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
  }

  onLink(fragment:any){    
    this.navigationService.scrollTo(fragment);
  }

  onCookie(){
    this.bottomSheet.open(CookieComponent,{disableClose:true});
  }

}
