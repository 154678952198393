import { Component, OnInit } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';

@Component({
  selector: 'app-team-part',
  templateUrl: './team-part.component.html',
  styleUrls: ['./team-part.component.scss']
})
export class TeamPartComponent implements OnInit {

  constructor(public textService: TextsService) { }

  ngOnInit() {
  }

}
