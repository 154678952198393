import { TableComponent } from './components/sub-pages/privacy-part/table/table.component';
import { CookieComponent } from './components/cookie/cookie.component';
import { PrivacyPartComponent } from './components/sub-pages/privacy-part/privacy-part.component';
import { TextComponent } from './layouts/text/text.component';
import { SliderComponent } from './components/sub-pages/references-part/slider/slider.component';
import { TeamPartComponent } from './components/sub-pages/team-part/team-part.component';
import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { CoverPartComponent } from './components/sub-pages/cover-part/cover-part.component';
import { InfoPartComponent } from './components/sub-pages/info-part/info-part.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ReferencesPartComponent } from './components/sub-pages/references-part/references-part.component';
import { ContactsPartComponent } from './components/sub-pages/contacts-part/contacts-part.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { TeamBadgeComponent } from './components/sub-pages/team-part/team-badge/team-badge.component';
import { SwiperModule } from "swiper/angular";
import { ProjectCardComponent } from './components/sub-pages/project-part/project-card/project-card.component';
import { ProjectPartComponent } from './components/sub-pages/project-part/project-part.component';
import { SlideComponent } from './components/sub-pages/references-part/slide/slide.component';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ImpressumPartComponent } from './components/sub-pages/impressum-part/impressum-part.component';
import { BlogPartComponent } from './components/sub-pages/blog-part/blog-part.component';
import { BlogCardComponent } from './components/sub-pages/blog-part/blog-card/blog-card.component';
import { BlogPagePartComponent } from './components/sub-pages/blog-page-part/blog-page-part.component';
import { CookieService } from './models/services/cookie-service/cookie.service';
import { NgDompurifyModule } from '@tinkoff/ng-dompurify';

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    CoverPartComponent,
    InfoPartComponent,
    NavigationComponent,
    ReferencesPartComponent,
    ContactsPartComponent,
    TeamPartComponent,
    TeamBadgeComponent,
    FooterComponent,
    ProjectPartComponent,
    ProjectCardComponent,
    SliderComponent,
    SlideComponent,
    TextComponent,
    PrivacyPartComponent,
    CookieComponent,
    ImpressumPartComponent,
    TableComponent,
    BlogPartComponent,
    BlogCardComponent,
    BlogPagePartComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,    
    RecaptchaModule,
    SwiperModule,
    HttpClientModule,
    MatSnackBarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatMenuModule,
    NgxGoogleAnalyticsModule.forRoot(environment.sqAnalyticsKey, environment.analyticsKey.length > 0 ? [{command:"config",values:[environment.analyticsKey]},{command:"config",values:[environment.sqAnalyticsKey]}] : []),
    NgxGoogleAnalyticsRouterModule,
    NgDompurifyModule
  ],
  providers: [{ provide: RECAPTCHA_SETTINGS, useValue: {siteKey :environment.recaptchaSiteKey }}],
  bootstrap: [AppComponent]
})
export class AppModule {  
  constructor(cookie:CookieService){}
}
