<footer>
    <div class="logo">
        <img src="assets/icons/logo.webp" alt="logo">
        <div><span >SIDE</span><span class="orange">QUEST</span></div>
    </div>
    <div class="side-links">
        <div class="faded">
            <a [routerLink]="'/'+textService.actLang+'/privacy-policy'" (click)="onLink('')" >{{textService.getText("adatvedelmi_iranyelvek")}}</a>
        </div>                 
        <div class="faded">
            <a [routerLink]="'/'+textService.actLang+'/privacy-policy'" fragment="cookie" (click)="onLink('cookie')">{{textService.getText("suti_tajekoztato")}}</a>
        </div>  
        <div class="faded">
            <a (click)="onCookie()">{{textService.getText("suti_kezelese")}}</a>
        </div>                
        <div class="faded">
            <a [routerLink]="'/'+textService.actLang+'/impressum'" (click)="onLink('')">{{textService.getText("impresszum")}}</a>
        </div> 
        <div *ngIf="textService.actLang == 'hu'" class="faded">
            <a [routerLink]="'/'+textService.actLang+'/blog'" (click)="onLink('')">{{textService.getText("blog")}}</a>
        </div>
    </div>
    <div class="bottom">
        <div class="left">
            <a class="mail" href="mailto:{{textService.getText('kapcsolat_email')}}"><img src="assets/icons/envelope-regular.svg" alt="mail"></a>
        </div>
        <div class="right">  
            <div>            
                <a class="mail" href="mailto:{{textService.getText('kapcsolat_email')}}">{{textService.getText('kapcsolat_email')}}</a>                
            </div>     

            <div class="faded">
                {{textService.getText("copyright")}}
            </div>
            
        </div>

    </div>


</footer>
