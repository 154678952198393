import { Component, OnInit } from '@angular/core';
import { TextsService } from 'src/app/models/services/texts-service/texts.service';

@Component({
  selector: 'app-project-part',
  templateUrl: './project-part.component.html',
  styleUrls: ['./project-part.component.scss']
})
export class ProjectPartComponent implements OnInit {

  constructor(public textService: TextsService) { }

  ngOnInit() {
  }

}
