<div class="holder">
    <div class="card left">
        <div class="logo">
            <img [src]="logo" alt="logo">
        </div>
        <div class="text1">
            {{mainText}}
        </div>
        <div class="text2" [innerHTML]="subText">
            
        </div>
    </div>
    <div class="card right">
        <strong>{{textService.getText('referencia_funkciok')}}</strong>
        <hr>
        <div class="list">
            <div class="item" *ngFor="let item of list.split(';')" >
                <img src="/assets/icons/caret-square-right-solid.svg" alt="icon"> <span>{{item}}</span> 
            </div>

        </div>
    </div>
</div>