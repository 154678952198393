import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { CookieComponent } from './components/cookie/cookie.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private bottomSheet: MatBottomSheet){
    if(localStorage.getItem(environment.cookieStorageKey) != '1'){
      this.bottomSheet.open(CookieComponent,{disableClose:true,hasBackdrop:false});
    }  
  }
}
