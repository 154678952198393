import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-badge',
  templateUrl: './team-badge.component.html',
  styleUrls: ['./team-badge.component.scss']
})
export class TeamBadgeComponent implements OnInit {

  @Input() name:string = "Jani";
  @Input() title:string = "CEO";
  @Input() profile:string = "";
  style:any;

  constructor() { }

  ngOnInit() {
    this.style = {
      'background-image': 'url('+this.profile+')'
    };
  }

}
