<section id='project' class="team-part">

  <div class="content">
    <div class="card-holder">
      <div class="item">
        <app-project-card [number]="0" [title]="textService.getText('project_card_cim')" [text]="textService.getText('project_card_leiras')" [transparent]="true"></app-project-card>
      </div>
      <div class="item">
        <app-project-card [number]="1" [title]="textService.getText('project_card_1_cim')" [text]="textService.getText('project_card_1_leiras')"></app-project-card>
      </div>
      <div class="item">
        <app-project-card [number]="2" [title]="textService.getText('project_card_2_cim')" [text]="textService.getText('project_card_2_leiras')"></app-project-card>
      </div>
      <div class="item">
        <app-project-card [number]="3" [title]="textService.getText('project_card_3_cim')" [text]="textService.getText('project_card_3_leiras')"></app-project-card>
      </div>
      <div class="item">
        <app-project-card [number]="4" [title]="textService.getText('project_card_4_cim')" [text]="textService.getText('project_card_4_leiras')"></app-project-card>
      </div>
      <div class="item">
        <app-project-card [number]="5" [title]="textService.getText('project_card_5_cim')" [text]="textService.getText('project_card_5_leiras')"></app-project-card>
      </div>
    </div>

  </div>

</section>
    